import React, { useState, useEffect, useRef, useContext  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import MainLayout from '../components/MainLayout';
import SignatureCanvas from 'react-signature-canvas';
import { useToast } from '../contexts/ToastContext'; // Importer useToast hooken
import config from '../config';
import './AgreementNewPreview.css';
import { LanguageContext } from '../contexts/LanguageContext';

const AgreementNewPreview = () => {
  const { kundeId, avtaleId, behandlingsadrId } = useParams();
  const navigate = useNavigate();
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext);
  const [agreementDetails, setAgreementDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signingError, setSigningError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailAgreement, setEmailAgreement] = useState(false);
  const [signed, setSigned] = useState(false);
  const customerSigRef = useRef(null);
  const technicianSigRef = useRef(null);

  useEffect(() => {
    const fetchAgreementDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAgreementDetails/${avtaleId}/?token=${token}`);
        if (response.data.status === 'success') {
          setAgreementDetails(response.data.data);
        } else {
          notify('error', translations.failedToFetchAgreementDetails || 'Failed to fetch agreement details.');
        }
      } catch (error) {
        console.error(error);
        notify('error', translations.fetchAgreementError || 'An error occurred while fetching agreement details.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgreementDetails();
  }, [avtaleId, translations, notify]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSaveClick = async () => {
    if (customerSigRef.current.isEmpty() || technicianSigRef.current.isEmpty()) {
      setSigningError(translations.bothSignaturesRequired || 'Both signatures are required.');
      return;
    }
    setSigningError('');

    const customerSignature = customerSigRef.current.getTrimmedCanvas().toDataURL('image/png');
    const technicianSignature = technicianSigRef.current.getTrimmedCanvas().toDataURL('image/png');
    const userId = localStorage.getItem('userid');
    const currentDate = new Date().toISOString();

    const payload = {
      avtale_id: avtaleId,
      email: emailAgreement ? 'true' : 'false',
      sign_vendor: technicianSignature,
      sign_customer: customerSignature,
      created_date: currentDate,
      created_by: userId,
      agrmt_doc_conf_id: 3,
    };

    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.post(`${config.apiUrl}/addAgreementDocSigned/?token=${token}`, payload);
      if (response.data.status === 'success') {
        notify('success', translations.agreementSignedSuccess || 'Agreement signed and uploaded successfully!');
        setSigned(true);
        closeModal();
        sessionStorage.setItem('previousPath', '/mainmenu');
        navigate('/mainmenu');
      } else {
        notify('error', translations.agreementSignError || 'Failed to sign and upload the agreement.');
      }
    } catch (error) {
      console.error(error);
      notify('error', translations.agreementSignError || 'An error occurred while signing the agreement.');
    }
  };

  if (loading) {
    return (
      <MainLayout title={translations.agreementPreviewTitle || 'Agreement New Preview'}>
        <div>{translations.loading || 'Loading...'}</div>
      </MainLayout>
    );
  }

  if (!agreementDetails) {
    return (
      <MainLayout title={translations.agreementPreviewTitle || 'Agreement New Preview'}>
        <div>{translations.failedToLoadAgreementDetails || 'Failed to load agreement details'}</div>
      </MainLayout>
    );
  }

  const { agreementDetails: details = [], companyDetails = [], Behandlingsadr = [], InvProducts = [], Treatments = [] } = agreementDetails;
  const detail = details[0] || {};
  const company = companyDetails[0] || {};
  const address = Behandlingsadr[0] || {};
  const treatments = Treatments.map(treat => treat.behandling).join(', ');
  const products = InvProducts.map((product, index) => (
    <tr key={index}>
      <td>{product.product_name || ''}</td>
      <td>{product.price_ex_vat || ''}</td>
      <td>{product.qty || ''}</td>
      <td>{product.discount || ''}</td>
      <td>{product.vat_percent || ''}</td>
      <td>{product.tot_price_ex_vat || ''}</td>
      <td>{product.vat_percent ? (product.tot_price_ex_vat * (1 + product.vat_percent / 100)).toFixed(2) : ''}</td>
    </tr>
  ));

  const totalExMVA = InvProducts.reduce((acc, product) => acc + parseFloat(product.tot_price_ex_vat || 0), 0).toFixed(2);
  const totalInclMVA = InvProducts.reduce((acc, product) => acc + parseFloat(product.tot_price_ex_vat || 0) * (1 + (product.vat_percent || 0) / 100), 0).toFixed(2);

  return (
    <MainLayout title={translations.agreementPreviewTitle || 'Agreement New Preview'}>
      <div className="agreement-preview-container">
        <div className="agreement-preview-page">
          <table className="agreement-preview-table">
            <thead>
              <tr>
                <th colSpan="2" className="table-title">SERVICE / AVTALE</th>
              </tr>
            </thead>
            <tbody>
              {/* Table 2: Customer Info */}
              <tr>
                <td colSpan="2">
                  <table className="customer-info-table">
                    <tbody>
                      <tr>
                        <td><strong>Kunde</strong></td>
                        <td>{detail.navn || ''}</td>
                        <td rowSpan="8" className="company-info">
                          {company.company_logo && <img src={company.company_logo} alt="Company Logo" className="company-logo" />}<br />
                          <strong>{company.company || ''}</strong><br />
                          {company.company_address || ''}<br />
                          {company.company_zip_code || ''} {company.company_postal || ''}<br />
                          {company.company_telephone || ''}<br />
                          {company.company_mail || ''}<br />
                          {company.company_orgno || ''}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Avtalenr</strong></td>
                        <td>{detail.avtale_id || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>Org.Nr.</strong></td>
                        <td>{detail.organization_number || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>Postnr/Sted</strong></td>
                        <td>{detail.postnr || ''} {detail.poststed || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>Kontaktperson</strong></td>
                        <td>{detail.kontakt || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>Telefon</strong></td>
                        <td>{detail.telefon || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>E-post</strong></td>
                        <td>{detail.epost || ''}</td>
                      </tr>
                      <tr>
                        <td><strong>Oppstartsdato</strong></td>
                        <td>{detail.dato_start || ''}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {/* Table 3: Behandlingsadresse */}
              <tr>
                <td colSpan="2" className="gray-background">
                  <strong>Behandlingsadresse</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {address.behandlingsadr || ''} {address.postnr || ''} {address.poststed || ''}
                </td>
              </tr>
              {/* Table 4: Kontroll/behandling mot */}
              <tr>
                <td><strong>Kontroll/behandling mot:</strong></td>
                <td>{treatments}</td>
              </tr>
              <tr>
                <td><strong>Arbeidsområde:</strong></td>
                <td>{detail.work_area || ''}</td>
              </tr>
              {/* Table 5: Arbeidsbeskrivelse */}
              <tr>
                <td colSpan="2" className="gray-background">
                  <strong>Arbeidsbeskrivelse</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {detail.work_descr || ''}
                </td>
              </tr>
              {/* Table 6: Øvrig informasjon */}
              <tr>
                <td colSpan="2" className="gray-background">
                  <strong>Øvrig informasjon</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {detail.other_info || ''}
                </td>
              </tr>
              {/* Table 7: Priser og betingelser */}
              <tr>
                <td colSpan="2" className="gray-background">
                  <strong>Priser og betingelser</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table className="prices-table">
                    <thead>
                      <tr>
                        <th>Beskrivelse</th>
                        <th>Enhetpris</th>
                        <th>Antall</th>
                        <th>Discount(%)</th>
                        <th>MVA (%)</th>
                        <th>Eko. MVA</th>
                        <th>Inkl. MVA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products}
                      <tr>
                        <td colSpan="5"></td>
                        <td>{totalExMVA}</td>
                        <td>{totalInclMVA}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {/* Table 8: Besøk pr. år */}
              <tr>
                <td><strong>Besøk pr. år:</strong></td>
                <td>{detail.kontroller || ''}</td>
              </tr>
              <tr>
                <td><strong>Varighet (Ant. år):</strong></td>
                <td>{detail.varighet || ''}</td>
              </tr>
              <tr>
                <td><strong>Fakturafrekvens:</strong></td>
                <td>{detail.inv_freq || ''}</td>
              </tr>
              <tr>
                <td><strong>Fakturaformat:</strong></td>
                <td>{detail.inv_type || ''}</td>
              </tr>
              {/* Table 9: Avtalevilkår */}
              <tr>
                <td colSpan="2" className="gray-background">
                  <strong>Avtalevilkår</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {detail.tac || ''}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="checkbox-section">
            <label>
              <input
                type="checkbox"
                checked={emailAgreement}
                onChange={(e) => setEmailAgreement(e.target.checked)}
              />
              {translations.sendAgreementByEmail || 'Send agreement via email'}
            </label>
          </div>

          <div className="button-section">
            <button className="sign-button" onClick={openModal}>
            {translations.signAgreement || 'Sign Agreement'}
              </button>
          </div>

          {showModal && (
            <div className="modal-agreement">
              <div className="modal-content-agreement">
                <span className="modal-close-agreement" onClick={closeModal}>&times;</span>
                <div className="signature-section-agreement">
                  <div className="signature-box-agreement">
                  <strong>{translations.customerSignature || 'Customer Signature'}</strong>
                    <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={customerSigRef} />
                    <button onClick={() => customerSigRef.current.clear()}>{translations.reset || 'Reset'}</button>
                  </div>
                  <div className="signature-box-agreement">
                  <strong>{translations.technicianSignature || 'Technician Signature'}</strong>
                    <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={technicianSigRef} />
                    <button onClick={() => technicianSigRef.current.clear()}>{translations.reset || 'Reset'}</button>
                  </div>
                  {signingError && <div className="error-message">{signingError}</div>}
                  <div className="modal-button-section-agreement">
                  <button className="save-agr-button" onClick={handleSaveClick}>
                      {translations.save || 'Save'}
                    </button>
                    <button className="cancel-agr-button" onClick={closeModal}>
                      {translations.cancel || 'Cancel'}
                    </button>
                  </div>
                </div>
              </div> 
            </div>
          )}
        </div>   
      </div>
    </MainLayout>
  );
};

export default AgreementNewPreview;
