import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './EffortSummary.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import { FaClock, FaRoad, FaStickyNote } from 'react-icons/fa';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner';
import { LanguageContext } from '../../contexts/LanguageContext'; // Legg til LanguageContext for oversettelser

const EffortsSummary = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [nextVisitDate, setNextVisitDate] = useState(null);
  const [isNextVisitDateRequired, setIsNextVisitDateRequired] = useState(false);
  const [closeAgreement, setCloseAgreement] = useState(false); 
  const [showEfforts, setShowEfforts] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [distance, setDistance] = useState('');
  const [notes, setNotes] = useState('');
  const [travelTimeId, setTravelTimeId] = useState(null);
  const [visitTypes, setVisitTypes] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState('');
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const notify = useToast();
  const { translations } = useContext(LanguageContext); // Legg til oversettelser

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchSystemVariable = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getSystemVariable/NEW_ASSIGNMENT/?token=${token}`, { signal });
        if (response.data && response.data.data) {
          const variable = response.data.data.find(item => item.key === "ASSIGNMENT_TRAVEL_TIME");
          setShowEfforts(variable && variable.value === "1");
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching system variable:', error);
          notify('error', translations.systemVariableError || 'Failed to fetch system variable. Please try again.');
        }
      }
    };

    const fetchEffortsSummary = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getEffortsSummary/${oppdrag_id}/?token=${token}`, { signal });
        if (response.data && response.data.data) {
          const data = response.data.data;
          setNextVisitDate(data.nextVisitDate ? new Date(data.nextVisitDate) : null);
          setIsNextVisitDateRequired(data.isNextVisitDateRequired);
          setShowEfforts(data.showEfforts);
          setDistance(data.distance || '');
          setNotes(data.notes || '');
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching efforts summary:', error);
          notify('error', translations.effortsSummaryError || 'Failed to fetch efforts summary. Please try again.');
        }
      }
    };

    const fetchAssignmentEfforts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAssignmentEfforts/${oppdrag_id}/?token=${token}`, { signal });
        if (response.data && response.data.data && response.data.data.length > 0) {
          const effort = response.data.data[0];
          setStartTime(effort.time_start ? new Date(effort.time_start) : null);
          setEndTime(effort.time_stop ? new Date(effort.time_stop) : null);
          setTravelTimeId(effort.travel_time_id);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching assignment efforts:', error);
          notify('error', translations.assignmentEffortsError || 'Failed to fetch assignment efforts. Please try again.');
        }
      }
    };

    const fetchVisitTypes = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getVisitType/?token=${token}`, { signal });
        if (response.data && response.data.data) {
          setVisitTypes(response.data.data);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching visit types:', error);
          notify('error', translations.visitTypesError || 'Failed to fetch visit types. Please try again.');
        }
      }
    };

    const fetchSelectedVisitType = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getVisitTypeOppdrag/${oppdrag_id}/?token=${token}`, { signal });
        if (response.data && response.data.data && response.data.data.length > 0) {
          setSelectedVisitType(response.data.data[0].type_besok_id);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching selected visit type:', error);
          notify('error', translations.selectedVisitTypeError || 'Failed to fetch selected visit type. Please try again.');
        }
      }
    };

    const fetchData = async () => {
      await fetchSystemVariable();
      await fetchEffortsSummary();
      await fetchAssignmentEfforts();
      await fetchVisitTypes();
      await fetchSelectedVisitType();
      setLoading(false);
    };

    fetchData();

    const handlePopState = () => {
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);

    return () => {
      controller.abort();
      window.removeEventListener('popstate', handlePopState);
    };
  }, [oppdrag_id, token, notify, translations]);

  const handleNextVisitDateChange = () => {
    const newState = !isNextVisitDateRequired;
    setIsNextVisitDateRequired(newState);

    if (!newState) {
        // Når bruker avhaker "Set next visit date", skal det være mulig å lukke avtalen
        setCloseAgreement(false);
    }
  };  



  const handleCloseAgreementChange = async () => {
    const newCloseAgreementState = !closeAgreement;

    if (newCloseAgreementState) {
        setIsNextVisitDateRequired(true); // Automatisk avhuke "Set next visit date" når man lukker avtalen
    }

    setCloseAgreement(newCloseAgreementState);

    if (newCloseAgreementState) {
        try {
            const response = await apiClient.get(`${config.apiUrl}/getServiceAddressCount/${behandlingsadrId}?token=${token}`);
            if (response.data.status === 'success') {
                const addressCount = response.data.behandlingsadr_cnt;
                if (addressCount > 1) {
                    const confirmClose = window.confirm(
                        `The agreement you are trying to close has ${addressCount} active service addresses associated with it. Are you sure you want to close the agreement?`
                    );
                    if (!confirmClose) {
                        setCloseAgreement(false);
                        setIsNextVisitDateRequired(true); // Gjenoppretter "Set next visit date"
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching service address count:', error);
            notify('error', 'Failed to fetch service address count.');
        }
    }
  };

  // Håndtering av lagring inkludert lukking av avtale
  const handleSave = async () => {
    const userId = localStorage.getItem('userid');
    let missingFields = [];

    // **NY SJEKK:** Hvis "Set next visit date" IKKE er avhuket, MÅ bruker velge både dato og behandlingstype!
    if (!isNextVisitDateRequired) {
        if (!nextVisitDate) {
            missingFields.push(translations.nextVisitDate || "Next visit date");
        }
        if (!selectedVisitType) {
            missingFields.push(translations.visitType || "Visit type");
        }
    }

    if (showEfforts) {
        if (!startTime) {
            missingFields.push(translations.startTime || "Start time");
        }
        if (!endTime) {
            missingFields.push(translations.endTime || "End time");
        }
        if (!distance) {
            missingFields.push(translations.kilometer || "Kilometer");
        }
    }

    if (missingFields.length > 0) {
      const errorMessage = `${translations.fillRequiredFields || 'Please fill in the following required fields:'} ${missingFields.join(", ")}`;
        window.alert(errorMessage);
        
        notify('error', errorMessage);
        return;
    }

    try {
        // LAGRE NY BESØKSDATO HVIS "Set next visit date" IKKE er avhuket
        if (!isNextVisitDateRequired) {
            await apiClient.post(`${config.apiUrl}/createNextVisit/?token=${token}`, {
                type_besok_id: selectedVisitType || "",
                user_id: userId,
                status_id: 0,
                dato_start: nextVisitDate.toISOString().slice(0, 10).replace(/-/g, '.'),
                behandlingsadr_id: behandlingsadrId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        // LAGRE ARBEIDSINNSATS HVIS "showEfforts" ER AKTIVERT
        if (showEfforts) {
            await apiClient.put(`${config.apiUrl}/updAssignmentEfforts/?token=${token}`, {
                notes: notes || "",
                oppdrag_id,
                time_start: startTime ? startTime.toISOString().slice(0, 16) : null,
                time_stop: endTime ? endTime.toISOString().slice(0, 16) : null,
                travel_length: distance,
                travel_time_id: travelTimeId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        // LUKK AVTALE HVIS "No further visits needed – close agreement" ER AVHUKET
        if (closeAgreement) {
            await apiClient.put(`${config.apiUrl}/updCloseAgreement/${behandlingsadrId}/?token=${token}`);
            notify('success', 'Agreement closed successfully.');
        }

        notify('success', translations.assignmentSaved || 'Assignment and report saved successfully.');
        navigate(`/Mainmenu/`);

    } catch (error) {
        console.error('Error saving efforts summary:', error);
        notify('error', translations.saveEffortsError || 'Failed to save efforts summary. Please try again.');
    }
};


  const getFieldClass = (value, isMandatory) => {
    if (isMandatory && !value) return "es-mandatory";
    if (isMandatory && value) return "es-filled";
    return "";
  };

  const handleDistanceChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setDistance(value);
    }
  };

  return (
    <MainLayout title={translations.effortsSummary || "Efforts Summary"}>
      {loading ? (
        <div className="es-loading">{translations.loading || 'Loading...'}</div>
      ) : (
        <div className="es-container">
          <div className="es-box">
            <OppdragInfoBanner />
            <h2 className="es-heading">{translations.setNewVisitDate || 'Do you want to set a new date for the next visit?'}</h2>
            <div className="es-checkbox-container">
              <label>
                  <input
                      type="checkbox"
                      checked={isNextVisitDateRequired}
                      onChange={handleNextVisitDateChange}
                  />
                  {translations.notNecessaryToSetDate || 'Not necessary to set a date'}
              </label>
          </div>
            {/* Checkbox: No further visits needed – close agreement */}
          <div className="es-checkbox-container">
              <label>
                  <input
                      type="checkbox"
                      checked={closeAgreement}
                      onChange={handleCloseAgreementChange}
                      disabled={!isNextVisitDateRequired} // Kan kun velges hvis "Set next visit date" er avhuket
                  />
                  No further visits needed – close agreement
              </label>
          </div>
            {/* DatePicker vises kun når "Set next visit date" IKKE er avhuket */}
          {!isNextVisitDateRequired && (
              <div className="es-datepicker-container">
                  <DatePicker
                      selected={nextVisitDate}
                      onChange={(date) => setNextVisitDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={translations.datePlaceholder || "dd/mm/yyyy"}
                      className={`es-datepicker ${getFieldClass(nextVisitDate, true)}`}
                  />
                <select
                  className={`es-visit-type-dropdown ${getFieldClass(selectedVisitType, true)}`}
                  value={selectedVisitType}
                  onChange={(e) => setSelectedVisitType(e.target.value)}
                >
                  <option value="">{translations.selectVisitType || "Select visit type"}</option>
                  {visitTypes.map((type) => (
                    <option key={type.type_besok_id} value={type.type_besok_id}>
                      {type.type_besok}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {showEfforts && (
            <div className="es-effort-container">
              <div className="es-box">
                <div className="es-effort-item">
                  <FaClock className="es-effort-icon" />
                  <h3 className="es-effort-title">{translations.workingTime || "Working Time"}</h3>
                  <div className="es-effort-time">
                    <div>
                      <label>{translations.startTime || "Start Time"}</label>
                      <DatePicker
                        selected={startTime}
                        onChange={(date) => setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={`es-datepicker ${getFieldClass(startTime, true)}`}
                      />
                    </div>
                    <div>
                      <label>{translations.endTime || "End Time"}</label>
                      <DatePicker
                        selected={endTime}
                        onChange={(date) => setEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={`es-datepicker ${getFieldClass(endTime, true)}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="es-effort-item es-effort-input-container">
                  <FaRoad className="es-effort-icon" />
                  <h3 className="es-effort-title">{translations.distance || "Distance"}</h3>
                  <input
                    type="text"
                    value={distance}
                    onChange={handleDistanceChange}
                    placeholder={translations.kilometersPlaceholder || "Kilometer"}
                    className={`es-effort-input ${getFieldClass(distance, true)}`}
                  />
                </div>
                <div className="es-effort-item es-effort-textarea-container">
                  <FaStickyNote className="es-effort-icon" />
                  <h3 className="es-effort-title">{translations.notes || "Notes"}</h3>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder={translations.notesPlaceholder || "Your text here.."}
                    className="es-effort-textarea"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="es-effort-buttons">
            <button className="es-effort-button es-effort-save-button" onClick={handleSave}>
              {translations.save || "Save"}
            </button>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default EffortsSummary;
