import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaListAlt, FaHistory } from 'react-icons/fa';
import Modal from 'react-modal';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const Risk = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [risikovurderingTiltak, setRisikovurderingTiltak] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialRisikovurderingTiltak, setInitialRisikovurderingTiltak] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTexts, setModalTexts] = useState([]);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyTexts, setHistoryTexts] = useState([]);
  const [setTextFunction, setSetTextFunction] = useState(() => () => {});
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getRiskOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.length > 0) {
          setRisikovurderingTiltak(response.data.data[0].risikovurdering_tiltak || '');
          setInitialRisikovurderingTiltak(response.data.data[0].risikovurdering_tiltak || '');
        }
      } catch (error) {
        console.error('Error fetching risk oppdrag:', error);
        notify('error', translations.fetchRiskError || 'Failed to fetch risk assessment. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomTexts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomtext/6/?token=${token}`);
        if (response.data && response.data.data) {
          setCustomTexts(response.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching custom texts:', error);
        notify('error', translations.fetchCustomTextsError || 'Failed to fetch custom texts. Please try again.');
      }
    };

    fetchData();
    fetchCustomTexts();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (risikovurderingTiltak !== initialRisikovurderingTiltak) {
      const confirmSave = window.confirm(translations.unsavedChangesConfirm || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/result/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving risk assessment:', error);
          notify('error', translations.saveRiskError || 'Failed to save risk assessment. Please try again.');
        }
      } else {
        navigate(`/legacy/result/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/result/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (risikovurderingTiltak.trim() === '') {
      notify('error', translations.enterRiskError || 'Please enter the risk assessment.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updRisk/?token=${token}`, {
        oppdrag_id,
        risikovurdering_tiltak: risikovurderingTiltak,
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/products/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', translations.riskSaved || 'Risk assessment saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/products/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving risk assessment:', error);
      notify('error', translations.saveRiskError || 'Failed to save risk assessment. Please try again.');
    }
  };

  const handleCustomTextClick = (texts, setTextFunc) => {
    setModalTexts(texts);
    setSetTextFunction(() => setTextFunc);
    setIsModalOpen(true);
  };

  const handleHistoryClick = async () => {
    try {
        const response = await apiClient.post(
            `${config.apiUrl}/getReportHistory/?token=${token}`, 
            {
                behandlingsadr_id: behandlingsadrId,
                question: "risikovurdering_tiltak"
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.data && response.data.data && response.data.data.length > 0) {
            const sortedData = response.data.data
                .filter(item => item.risikovurdering_tiltak && item.risikovurdering_tiltak.trim() !== "") 
                .sort((a, b) => new Date(b.dato_ferdig) - new Date(a.dato_ferdig))
                .map(item => ({
                    text: item.risikovurdering_tiltak.trim(),
                    date: item.dato_ferdig
                }));

            setHistoryTexts(sortedData);
        } else {
            setHistoryTexts([]);
        }
    } catch (error) {
        console.error('Error fetching report history:', error);
        notify('error', translations.fetchHistoryError || 'Failed to fetch report history.');
    }

    setIsHistoryModalOpen(true);
};

  const handleTextSelect = (text) => {
    setTextFunction((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    if (value) {
      return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
    }
    return 'ml-mandatory';
  };

  return (
    <MainLayout title={translations.riskTitle || 'Risk Assessment'}>
      <div className="cr-container">
        <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <div className="cr-box">
            <h2 className="cr-heading">{translations.riskHeading || 'Risk Assessment:'}</h2>
            <div className="cr-textarea-container">
              <textarea
                className={`cr-textarea ${getTextAreaClass(risikovurderingTiltak)}`}
                placeholder={translations.enterRiskPlaceholder || 'Enter the risk assessment'}
                value={risikovurderingTiltak}
                onChange={(e) => setRisikovurderingTiltak(e.target.value)}
              />
              <div className="cr-icon-container">
                <FaListAlt className="cr-icon" onClick={() => handleCustomTextClick(customTexts, setRisikovurderingTiltak)} />
                <FaHistory className="cr-icon" onClick={handleHistoryClick} />
              </div>
            </div>

          </div>
        )}
        <div className="cr-buttons">
          <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || 'Back'}</button>
          <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.saveButton || 'Save'}</button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectCustomText || 'Select Custom Text'}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectText || 'Select a text'}</h2>
        <ul className="cr-modal-list">
          {modalTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">{translations.closeButton || 'Close'}</button>
      </Modal>
      {/* Report History Modal */}
      {/* Report History Modal */}
      <Modal
          isOpen={isHistoryModalOpen}
          onRequestClose={() => setIsHistoryModalOpen(false)}
          contentLabel={translations.reportHistory || 'Report History'}
          className="cr-modal"
          overlayClassName="cr-overlay"
      >
          <h2>{translations.selectHistory || 'Select a previous comment'}</h2>
          <ul className="cr-modal-list">
              {historyTexts.length > 0 ? historyTexts.map((item, index) => (
                  <li key={index} onClick={() => {
                    setRisikovurderingTiltak(prev => `${prev}\n${item.text}`);
                    setIsHistoryModalOpen(false); // Close the modal right after setting the state
                }} className="cr-modal-item">
                    {item.text} <br />
                    <small>{new Date(item.date).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" })}</small>
                </li>                
              )) : <p>{translations.noHistory || 'No previous comment found'}</p>}
          </ul>
          <button onClick={() => setIsHistoryModalOpen(false)} className="cr-button cr-cancel-button">
              {translations.close || 'Close'}
          </button>
      </Modal>


    </MainLayout>
  );
};

export default Risk;
