import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';

const Filter = ({
  priorityFilter, treatmentFilter, treatmentOptions,
  visitFilter, setVisitFilter, // Inkludert som props
  setPriorityFilter, setTreatmentFilter, handleFilterChange, disabled
}) => {
  const { translations } = useContext(LanguageContext);

  const handlePriorityChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newPriorityFilter = checked 
      ? [...priorityFilter, parseInt(value)] 
      : priorityFilter.filter(item => item !== parseInt(value));
    setPriorityFilter(newPriorityFilter);
    handleFilterChange(newPriorityFilter, treatmentFilter, visitFilter);
  };

  const handleTreatmentChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newTreatmentFilter = checked 
      ? [...treatmentFilter, parseInt(value)] 
      : treatmentFilter.filter(item => item !== parseInt(value));
    setTreatmentFilter(newTreatmentFilter);
    handleFilterChange(priorityFilter, newTreatmentFilter, visitFilter);
  };

  const handleVisitChange = (e) => {
    const newVisitValue = parseInt(e.target.value);
    setVisitFilter(newVisitValue);
    handleFilterChange(priorityFilter, treatmentFilter, newVisitValue);
  };

  return (
    
    
    <div className={`filter-container ${disabled ? 'disabled' : ''}`}>
      <div className="filter-section">
        <h4>{translations['visits_per_year'] || 'Visits per year'}</h4>
        <input 
          type="range" 
          min="0" 
          max="12" 
          value={visitFilter} 
          onChange={handleVisitChange} 
          disabled={disabled} 
        />
        <p>{visitFilter === 0 ? translations['all_visits'] || 'All visits' : `${visitFilter} ${translations['visits'] || 'visits'}`}</p>
      </div>
      <div className="filter-section">
        <h4>{translations['priority'] || 'Priority'}</h4>
        {['Orange', 'Yellow', 'Green'].map((color, index) => (
          <label key={index}>
            <input 
              type="checkbox" 
              value={index + 1} 
              onChange={handlePriorityChange} 
              checked={priorityFilter.includes(index + 1)} 
              disabled={disabled} 
            />
            {translations[color.toLowerCase()] || color}
          </label>
        ))}
      </div>
      <div className="filter-section">
        <h4>{translations['treatment'] || 'Treatment'}</h4>
        {treatmentOptions.map(option => (
          <label key={option.behandling_id}>
            <input 
              type="checkbox" 
              value={option.behandling_id} 
              onChange={handleTreatmentChange} 
              checked={treatmentFilter.includes(option.behandling_id)} 
              disabled={disabled} 
            />
            {translations[option.behandling] || option.behandling}
          </label>
        ))}
      </div>

    </div>
  );
};

export default Filter;
