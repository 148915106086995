// src/api/apiClient.js
// En flles axios-instans vi sluser alle api-kall gjennom. Istedet for å kalle apiUrl fra hver side
// kaller vi istedet denne instansen. 
import axios from 'axios';
import config from '../config';

const apiClient = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Funksjoner for å håndtere tokens
//Denne funksjonen lagrer accessToken og refreshToken i nettleserens localStorage.
//Brukes vanligvis etter et vellykket påloggingsforsøk eller når du fornyer access tokenet via refreshAccessToken.

export function storeTokens(token, refreshToken) {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('token', token);
    if (refreshToken) {  // lagrer bare refreshtoken hvis den har en nyttig verdi.
        localStorage.setItem('refreshToken', refreshToken);
    }
}
//Brukes i request interceptor for å legge til access tokenet i Authorization-headeren før hver forespørsel.
function getAccessToken() {
    return localStorage.getItem('accessToken');
}
//Denne funksjonen henter refreshToken fra localStorage.
//Brukes i refreshAccessToken-funksjonen når access tokenet er utløpt, for å be om et nytt access token.
function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

//Denne funksjonen fjerner begge tokens fra localStorage, og dermed logger brukeren ut.
//Brukes når en feil oppstår ved fornyelse av token eller når du vil logge ut brukeren.
function clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

//Denne funksjonen sjekker om tokenet er utløpt ved å dekode exp-verdien (utløpstiden) fra tokenets payload.
//exp-verdien er i Unix-tidsstempel (sekunder siden 1. januar 1970), og sammenlignes med nåværende tidspunkt
//Brukes i request interceptor for å sjekke om access tokenet er utløpt før hver forespørsel sendes.
function isTokenExpired(token) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = payload.exp * 1000; // Konverter til millisekunder
    return Date.now() > expiry;
}

//Denne funksjonen forsøker å forny access tokenet ved å sende refreshToken til en refresh-endepunkt på API-et.
//Hvis API-et returnerer nye tokens, blir de lagret i localStorage, og det nye access tokenet returneres.
//Hvis fornyelsen mislykkes, fjernes tokens fra localStorage for å sikre at brukeren blir logget ut.
//Brukes i request interceptor når isTokenExpired returnerer true, noe som betyr at access tokenet har utløpt.
async function refreshAccessToken() {
    const refreshToken = getRefreshToken();
    try {
        const response = await axios.post(`${config.apiUrl}/refreshAccessToken`, {
            refreshToken: refreshToken,  // Sender refreshToken i riktig format
        });

        if (response.status === 200 && response.data.success) {
            const { token: accessToken, refreshToken: newRefreshToken } = response.data;
            storeTokens(accessToken, newRefreshToken);
            // Hvis du trenger å oppdatere brukerinformasjon på klienten, kan du gjøre det her
            // updateUserInfo(user);
            return accessToken;
        } else {
            clearTokens();
            throw new Error(response.data.message || 'Unable to refresh token');
        }
    } catch (error) {
        clearTokens();
        throw new Error(error.response?.data?.message || 'Failed to refresh token');
    }
}


// Legg til en request interceptor
// Denne interseptoren kjøres automatisk før hver HTTP-forespørsel som sendes med apiClient.
// Den sjekker om det finnes et access token (getAccessToken). Hvis det finnes, sjekker den om tokenet er utløpt (isTokenExpired).
// Hvis tokenet er utløpt, prøver den å forny det (refreshAccessToken). Hvis fornyelsen lykkes, oppdateres tokenet i Authorization-headeren.
// Hvis alt er i orden, settes Authorization-headeren med det gyldige access tokenet før forespørselen sendes.
// Denne interseptoren brukes automatisk før hver API-forespørsel som gjøres med apiClient.
apiClient.interceptors.request.use(async (config) => {
    let accessToken = getAccessToken();

    if (accessToken && isTokenExpired(accessToken)) {
        try {
            accessToken = await refreshAccessToken();
        } catch (error) {
            console.error('Token refresh failed', error);
            // Redirect to login or handle the error
            return Promise.reject(error);
        }
    }

    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default apiClient;


/* Oppsummering
storeTokens: Lagres access og refresh tokens i localStorage.
getAccessToken: Henter access token fra localStorage.
getRefreshToken: Henter refresh token fra localStorage.
clearTokens: Fjerner tokens fra localStorage for å logge ut brukeren.
isTokenExpired: Sjekker om access token er utløpt.
refreshAccessToken: Fornyer access token ved å bruke refresh token.
request interceptor: Håndterer tokens automatisk før hver forespørsel, og sørger for at et gyldig token er inkludert i forespørselen.
Denne strukturen sørger for at alle API-kall automatisk håndteres på en sikker måte, uten at du trenger å skrive om token-håndtering i hver enkelt komponent eller funksjon.*/

