import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "@mui/material";
import { Tldraw, createShapeId, AssetRecordType } from "@tldraw/tldraw";
import "@tldraw/tldraw/tldraw.css";

const EditImageModal = ({ show, onClose, imageUrl, onSaveImage }) => {
  const editorRef = useRef(null);
  const [canvasImage, setCanvasImage] = useState(null); // Opprinnelig bilde
  const [editorReady, setEditorReady] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const [mimeType, setMimeType] = useState("image/png");
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  // Konverter bildet til Base64 hvis det ikke allerede er det
  useEffect(() => {
    if (!imageUrl) return;

    if (imageUrl.startsWith("data:image")) {
      setImageBase64(imageUrl);
    } else {
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          setMimeType(blob.type);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageBase64(reader.result);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error("❌ Error converting image to Base64:", error));
    }
  }, [imageUrl]);

  // Laster inn originalbildet i canvas når modalen åpnes
  useEffect(() => {
    if (show) {
      setCanvasImage(null); // 🚀 Nullstill for å unngå at vi arver gamle verdier
  
      const img = new Image();
      img.src = imageUrl; // 🚀 Bruk alltid originalbildet
      img.onload = () => {
        setOriginalWidth(img.naturalWidth);
        setOriginalHeight(img.naturalHeight);
        setCanvasImage(img);
      };
    }
  }, [show, imageUrl]);
  

  // Når bildet er lastet, sett editorReady = true
  useEffect(() => {
    if (canvasImage) {
      setEditorReady(true);
    }
  }, [canvasImage]);

  // Når `Tldraw` er klart, legg til bildet
  const onEditorMount = (editor) => {
    editorRef.current = editor;

    // Fjern tidligere bilder fra Tldraw
    const existingImages = editor.getCurrentPageShapes().filter((shape) => shape.type === "image");
    existingImages.forEach((image) => editor.deleteShapes([image.id]));

    if (canvasImage && imageBase64) {
      const assetId = `asset:${createShapeId()}`;

      // Opprett en ny asset for bildet
      const asset = AssetRecordType.create({
        id: assetId,
        typeName: "asset",
        type: "image",
        props: {
          name: "Edited Image",
          src: imageBase64,
          w: originalWidth,
          h: originalHeight,
          isAnimated: false,
          mimeType: mimeType,
        },
      });

      // Legg til asset i Tldraw
      editor.store.put([asset]);

      // Opprett en bildeform og lås den
      editor.createShapes([
        {
          id: createShapeId(),
          type: "image",
          x: 0,
          y: 0,
          isLocked: true,
          props: {
            assetId,
            w: originalWidth,
            h: originalHeight,
          },
        },
      ]);
    }
  };

  const handleSave = async () => {
    if (!editorRef.current || !canvasImage) {
        console.error("❌ Editor eller bakgrunnsbilde mangler!");
        return;
    }

    const editor = editorRef.current;

    try {
        // 🚀 Hent alle former fra editoren (tegninger + bildet)
        const allShapes = editor.getCurrentPageShapes();
        if (!allShapes || allShapes.length === 0) {
            console.warn("⚠️ Ingen tegninger funnet i Tldraw. Eksporterer kun originalbildet.");
            const originalImage = imageBase64; // Bruk alltid originalt bilde
            onSaveImage(originalImage);
            onClose();
            return;
        }

        // 🚀 Eksporter kun tegningene som SVG (IKKE bildet)
        const drawnShapes = allShapes.filter(shape => shape.type !== "image");

        // 🚀 Hent bounds for alle tegningene
        const bounds = editor.getCurrentPageBounds();
        if (!bounds) {
            console.error("❌ Kunne ikke hente bounds for tegningene.");
            return;
        }

        // 🚀 Eksporter SVG med riktig bounds
        const svgElement = await editor.getSvg(drawnShapes, {
            bounds: bounds,
            background: false, // Ikke inkluder bakgrunn
        });

        const svgString = new XMLSerializer().serializeToString(svgElement);

        // 🚀 Konverter SVG til Image-objekt
        const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob);
        const svgImage = new Image();

        svgImage.onload = () => {
            // 🚀 Opprett canvas med **originalstørrelse**
            const exportCanvas = document.createElement("canvas");
            exportCanvas.width = originalWidth;
            exportCanvas.height = originalHeight;
            const ctx = exportCanvas.getContext("2d");

            // 🚀 Tegn ORIGINALBILDET i sin **opprinnelige størrelse**
            const baseImage = new Image();
            baseImage.src = imageUrl || imageBase64; 
            baseImage.onload = () => {
                ctx.drawImage(baseImage, 0, 0, originalWidth, originalHeight); // Sikre riktig størrelse

                // 🚀 Tegn SVG-en på riktig posisjon
                ctx.drawImage(
                    svgImage,
                    bounds.x, // Juster for formenes posisjon
                    bounds.y,
                    bounds.width,
                    bounds.height
                );

                // 🚀 Konverter til Base64 UTEN skalering eller tap
                const editedImage = exportCanvas.toDataURL("image/png", 1.0);

                console.log("📤 Sender redigert bilde fra EditImageModal:", editedImage);

                if (typeof onSaveImage === "function") {
                    onSaveImage(editedImage);
                }

                if (typeof onClose === "function") {
                    onClose();
                }

                // 🎯 Frigjør URL for SVG
                URL.revokeObjectURL(svgUrl);
            };
        };

        svgImage.src = svgUrl;
    } catch (error) {
        console.error("❌ Feil ved eksport av bilde fra Tldraw:", error);
    }
};


  return (
    <Dialog open={show} onClose={onClose} fullWidth maxWidth="md">
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2>Edit Image</h2>
        <div style={{ width: "100%", height: "500px", background: "#fff" }}>
          {editorReady ? <Tldraw onMount={onEditorMount} /> : <p>Loading editor...</p>}
        </div>
        <button onClick={handleSave} style={{ margin: "10px" }}>
          Save
        </button>
        <button onClick={onClose} style={{ margin: "10px" }}>
          Cancel
        </button>
      </div>
    </Dialog>
  );
};

export default EditImageModal;