import React, { useRef, useEffect} from "react";
import { Dialog } from "@mui/material";
import Croppie from "croppie";
import "croppie/croppie.css";

const CropImageModal = ({ show, imageFile, onClose, onCropComplete }) => {
  const croppieRef = useRef(null);
  const croppieInstanceRef = useRef(null); // Bruker useRef for å unngå state race conditions

  useEffect(() => {
    if (show && imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (croppieRef.current) {
          // 🚀 Slett gammel instans før ny initialisering
          if (croppieInstanceRef.current) {
            try {
              croppieInstanceRef.current.destroy();
            } catch (error) {
              console.warn("⚠️ Forsøk på å slette en udefinert Croppie-instans:", error);
            }
            croppieInstanceRef.current = null;
          }

          // ✅ Sjekk at reader.result ikke er null
          if (!reader.result) {
            console.error("❌ Ingen bilde-data funnet i FileReader.");
            return;
          }

          // ✅ Opprett en NY Croppie-instans
          const instance = new Croppie(croppieRef.current, {
            viewport: { width: 300, height: 200, type: "rectangle" },
            boundary: { width: 400, height: 300 },
            showZoomer: true,
            enableOrientation: true,
          });

          instance.bind({ url: reader.result }).catch((err) => {
            console.error("❌ Feil ved binding av Croppie:", err);
          });

          croppieInstanceRef.current = instance; // Lagre instansen
        }
      };
      reader.readAsDataURL(imageFile);
    }

    return () => {
      // 🚀 Robust opprydding: Sjekk at instansen finnes før ødeleggelse
      if (croppieInstanceRef.current) {
        try {
          croppieInstanceRef.current.destroy();
        } catch (error) {
          console.warn("⚠️ Forsøk på å slette en ikke-eksisterende Croppie-instans:", error);
        }
        croppieInstanceRef.current = null;
      }
    };
  }, [show, imageFile]);

  const handleCrop = async () => {
    if (!croppieInstanceRef.current) {
      console.error("❌ Croppie-instansen er ikke initialisert.");
      return;
    }

    try {
      const croppedImage = await croppieInstanceRef.current.result({
        type: "base64",
        size: { width: 600, height: 400 },
        format: "jpeg",
        quality: 0.5,
      });

      onCropComplete(croppedImage);
      onClose();
    } catch (error) {
      console.error("❌ Feil under beskjæring av bildet:", error);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          margin: "0 auto",
          overflow: "hidden",
        },
      }}
    >
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2>Crop Image</h2>
        <div ref={croppieRef} style={{ margin: "auto" }} />
        <button onClick={handleCrop} style={{ marginTop: "20px" }}>
          Crop & Save
        </button>
        <button onClick={onClose} style={{ marginLeft: "10px" }}>
          Cancel
        </button>
      </div>
    </Dialog>
  );
};

export default CropImageModal;
