// src/components/ControlPointModal.js
import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient';
import config from '../config';
import './ControlPointModal.css';
import { LanguageContext } from '../contexts/LanguageContext';

Modal.setAppElement('#root');

const ControlPointModal = ({ isOpen, onRequestClose, behandlingsadrId, oppdrag_id }) => {
  const { translations } = useContext(LanguageContext);
  const [checkpoints, setCheckpoints] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedCheckpoints, setExpandedCheckpoints] = useState({});
  const [consumptionValues, setConsumptionValues] = useState({});
  const [cannotObserveOptions, setCannotObserveOptions] = useState([]);
  const [cannotObserveValues, setCannotObserveValues] = useState({});
  const [commentValues, setCommentValues] = useState({});
  const [currentCheckpoint, setCurrentCheckpoint] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [checkpointLogs, setCheckpointLogs] = useState({});
  const token = localStorage.getItem('token');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getConsumptionOptionValue/?token=${token}`);
        if (response.data.status === 'success') {
          setOptions(response.data.data);
        }
      } catch (error) {
        console.error(translations['errorFetchingConsumptionOptions'] || "Feil ved henting av forbruksalternativer:", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (isOpen) {
      const fetchCheckpoints = async () => {
        try {
          const response = await apiClient.get(`${config.apiUrl}/getCheckpointByServiceAddress/${behandlingsadrId}?token=${token}`);
          if (response.data.status === 'success') {
            setCheckpoints(response.data.data);
          } else {
            console.error(translations['errorFetchingCheckpoints'] || 'Failed to fetch checkpoints');
          }
        } catch (error) {
          console.error(translations['errorFetchingCheckpoints'] || 'Error fetching checkpoints:', error);
        }
      };
      fetchCheckpoints();
    }
  }, [isOpen, behandlingsadrId, token]);

  useEffect(() => {
    if (isOpen) {
      fetchCheckpointLogs();
    }
  }, [isOpen, oppdrag_id, token]);

  useEffect(() => {
    const fetchCannotObserveOptions = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCannotObserveCp?token=${token}`);
        if (response.data.status === 'success') {
          setCannotObserveOptions(response.data.data);
        }
      } catch (error) {
        console.error(translations['errorFetchingCannotObserveOptions'] || "Feil ved henting av Cannot Observe-alternativer:", error);
      }
    };
    fetchCannotObserveOptions();
  }, []);

  const fetchCheckpointLogs = async () => {
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCheckpointRecord/${oppdrag_id}?token=${token}`);
      if (response.data.status === 'success') {
        const logs = response.data.data.reduce((acc, log) => {
          acc[log.checkpoint_id] = log;
          return acc;
        }, {});
        setCheckpointLogs(logs);
      } else {
        console.error(translations['errorFetchingCheckpointLogs'] || 'Failed to fetch checkpoint logs');
      }
    } catch (error) {
      console.error(translations['errorFetchingCheckpointLogs'] || 'Error fetching checkpoint logs:', error);
    }
  };

  const handleCannotObserveChange = (e, checkpointId) => {
    const value = e.target.value;
    setCannotObserveValues(prev => ({
      ...prev,
      [checkpointId]: value
    }));
    setConsumptionValues(prev => ({
      ...prev,
      [checkpointId]: undefined
    }));
  };

  const openCommentModal = (checkpointId) => {
    setCurrentCheckpoint(checkpointId);
    setIsCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  const handleCommentChange = (e) => {
    setCommentValues(prev => ({
      ...prev,
      [currentCheckpoint]: e.target.value
    }));
  };

  const groupedCheckpoints = checkpoints.reduce((acc, checkpoint) => {
    if (!acc[checkpoint.checkpoint_type_cat_id]) {
      acc[checkpoint.checkpoint_type_cat_id] = {
        category: checkpoint.checkpoint_type_cat,
        shapeType: checkpoint.shape_type,
        fillColor: checkpoint.fill_color,
        lineColor: checkpoint.line_color,
        checkpoints: []
      };
    }
    acc[checkpoint.checkpoint_type_cat_id].checkpoints.push(checkpoint);
    return acc;
  }, {});

  const getSVGIcon = (shapeType, fillColor, lineColor) => {
    switch (shapeType) {
      case "circle":
        return (
          <svg width="20" height="20">
            <circle cx="8" cy="8" r="6" fill={fillColor} stroke={lineColor} strokeWidth="1" />
          </svg>
        );
      case "triangle":
        return (
          <svg width="20" height="20">
            <polygon points="8,2 14,14 2,14" fill={fillColor} stroke={lineColor} strokeWidth="1" />
          </svg>
        );
      case "square":
        return (
          <svg width="20" height="20">
            <rect x="2" y="2" width="12" height="12" fill={fillColor} stroke={lineColor} strokeWidth="1" />
          </svg>
        );
      case "plus":
        return (
          <svg width="20" height="20">
            <rect x="7" y="2" width="2" height="12" fill={fillColor} stroke={lineColor} strokeWidth="1" />
            <rect x="2" y="7" width="12" height="2" fill={fillColor} stroke={lineColor} strokeWidth="1" />
          </svg>
        );
      default:
        return null;
    }
  };
  const handleInputChange = (e, checkpointId) => {
    const value = parseInt(e.target.value, 10) || 0;
    setConsumptionValues(prev => ({
      ...prev,
      [checkpointId]: value
    }));
  };

  const saveConsumption = async (checkpointId, recordType) => {
    try {
      const existingLog = checkpointLogs[checkpointId];
      if (existingLog) {
        await apiClient.put(`${config.apiUrl}/updCheckpointRecord/${existingLog.checkpoint_log_id}`, {
          token,
          record_value: consumptionValues[checkpointId] !== undefined ? consumptionValues[checkpointId] : null,
          comment: commentValues[checkpointId] || "",
          cannot_observe: cannotObserveValues[checkpointId] || null
        });
      } else {
        await apiClient.post(`${config.apiUrl}/addCheckpointRecord`, {
          token,
          assignment_id: oppdrag_id,
          checkpoint_id: checkpointId,
          created_at: new Date().toISOString(),
          record_type: recordType,
          record_value: consumptionValues[checkpointId] !== undefined ? consumptionValues[checkpointId] : null,
          comment: commentValues[checkpointId] || "",
          cannot_observe: cannotObserveValues[checkpointId] || null
        });
      }
      fetchCheckpointLogs();
    } catch (error) {
      console.error(translations['errorSavingConsumption'] || "Feil ved lagring av forbruk:", error);
    }
  };

  const ConsumptionButtons = ({ checkpointId, options, setConsumptionValues }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const handleOptionClick = (optionValue) => {
      setSelectedValue(optionValue);
      setConsumptionValues(prev => ({
        ...prev,
        [checkpointId]: optionValue
      }));
    };
    return (
      <div className="button-consumption">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(option.option_value)}
            className={selectedValue === option.option_value ? "selected" : ""}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={translations['controlPointsLabel'] || "Control Points"}
        className="control-point-modal"
        overlayClassName="custom-overlay"
      >
        <div className="modal-content">
          <h2>{translations['controlPointsTitle'] || "Control Points"}</h2>
          {Object.entries(groupedCheckpoints).length > 0 ? (
            Object.entries(groupedCheckpoints).map(([categoryId, group]) => (
              <div key={categoryId} className="checkpoint-category">
                <button
                  className="accordion-category-button"
                  style={{ backgroundColor: "#e0e0e0", border: "none" }}
                  onClick={() =>
                    setExpandedCategories(prev => ({
                      ...prev,
                      [categoryId]: !prev[categoryId]
                    }))
                  }
                >
                  {translations[group.category] || group.category} {getSVGIcon(group.shapeType, group.fillColor, group.lineColor)}
                </button>
                {expandedCategories[categoryId] && (
                  <div className="checkpoint-list">
                    {group.checkpoints.map((checkpoint) => (
                      <div key={checkpoint.checkpoint_id} className="checkpoint-container">
                        <button
                          className="accordion-checkpoint-button"
                          onClick={() =>
                            setExpandedCheckpoints(prev => ({
                              ...prev,
                              [checkpoint.checkpoint_id]: !prev[checkpoint.checkpoint_id]
                            }))
                          }
                        >
                          {(translations[checkpoint.label] || checkpoint.label)} - {(translations[checkpoint.room] || checkpoint.room)}
                        </button>
                        {expandedCheckpoints[checkpoint.checkpoint_id] && (
                          <div className="checkpoint-details">
                            <div className="checkpoint-info">
                              <p><strong>{translations['typeLabel'] || "Type"}:</strong> {translations[checkpoint.checkpoint_type] || checkpoint.checkpoint_type}</p>
                              <p><strong>{translations['baitTypeLabel'] || "Bait Type"}:</strong> {translations[checkpoint.bait_type] || checkpoint.bait_type}</p>
                              <p><strong>{translations['exactLocationLabel'] || "Exact Location"}:</strong> {translations[checkpoint.exact_location] || checkpoint.exact_location}</p>
                              {checkpointLogs[checkpoint.checkpoint_id] && (
                                <div className="checkpoint-log">
                                  <p><strong>{translations['statusLabel'] || "Status"}:</strong> {checkpointLogs[checkpoint.checkpoint_id].cannot_observe
                                    ? translations[checkpointLogs[checkpoint.checkpoint_id].reason] || checkpointLogs[checkpoint.checkpoint_id].reason
                                    : `${checkpointLogs[checkpoint.checkpoint_id].record_value} ${checkpointLogs[checkpoint.checkpoint_id].unit}`}
                                  </p>
                                  {checkpointLogs[checkpoint.checkpoint_id].comment && (
                                    <p><strong>{translations['commentLabel'] || "Comment"}:</strong> {checkpointLogs[checkpoint.checkpoint_id].comment}</p>
                                  )}
                                  <p className="checkpoint-log-date">
                                    <em>({new Date(checkpointLogs[checkpoint.checkpoint_id].created_at).toLocaleDateString()})</em>
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="checkpoint-consumption">
                              {checkpoint.checkpoint_consumption_id === 2 && (
                                <div className="button-consumption">
                                  <ConsumptionButtons
                                    checkpointId={checkpoint.checkpoint_id}
                                    options={options}
                                    setConsumptionValues={setConsumptionValues}
                                  />
                                </div>
                              )}
                              <div className="cannot-observe-dropdown">
                                <label></label>
                                <select
                                  value={cannotObserveValues[checkpoint.checkpoint_id] || ""}
                                  onChange={(e) => handleCannotObserveChange(e, checkpoint.checkpoint_id)}
                                >
                                  <option value="">{translations['inaccessibleOption'] || "Inaccessible?"}</option>
                                  {cannotObserveOptions.map((option) => (
                                    <option key={option.reason_id} value={option.reason_id}>
                                      {translations[option.reason] || option.reason}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {checkpoint.checkpoint_consumption_id === 1 && (
                                <div className="input-consumption-container">
                                  <label htmlFor={`consumption-${checkpoint.checkpoint_id}`} className="input-label"></label>
                                  <input
                                    id={`consumption-${checkpoint.checkpoint_id}`}
                                    type="number"
                                    min="0"
                                    placeholder={translations['countPlaceholder'] || "Count"}
                                    value={consumptionValues[checkpoint.checkpoint_id] || ''}
                                    onChange={(e) => handleInputChange(e, checkpoint.checkpoint_id)}
                                    disabled={!!cannotObserveValues[checkpoint.checkpoint_id]}
                                  />
                                </div>
                              )}
                              <div className="checkpoint-actions">
                                <button className="comment-button" onClick={() => openCommentModal(checkpoint.checkpoint_id)}>
                                  {translations['commentButton'] || "Comment"}
                                </button>
                                <div className="input-consumption-button">
                                  <button
                                    onClick={() => saveConsumption(checkpoint.checkpoint_id, checkpoint.checkpoint_consumption_id)}
                                    disabled={!cannotObserveValues[checkpoint.checkpoint_id] && consumptionValues[checkpoint.checkpoint_id] === undefined}
                                  >
                                    {translations['saveButton'] || "Save"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>{translations['noControlPointsAvailable'] || "No control points available."}</p>
          )}
          <button className="button close-button" onClick={onRequestClose}>
            {translations['closeButton'] || "Close"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isCommentModalOpen}
        onRequestClose={closeCommentModal}
        className="comment-modal"
        overlayClassName="modal-overlay"
      >
        <h2>{translations['addCommentTitle'] || "Add Comment"}</h2>
        <textarea
          value={commentValues[currentCheckpoint] || ''}
          onChange={handleCommentChange}
          placeholder={translations['commentPlaceholder'] || "Enter your comment here..."}
        />
        <button onClick={closeCommentModal}>
          {translations['closeButton'] || "Close"}
        </button>
      </Modal>
    </>
  );
};

export default ControlPointModal;