import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import Modal from 'react-modal';
import CustomerOverviewEditCustomer from '../components/CustomerOverviewEditCustomer';
import CustomerOverviewEditAgrmt from '../components/CustomerOverviewEditAgrmt';
import CustomerOverviewAddServiceAddress from '../components/CustomerOverviewAddServiceAddress';
import CustomerOverviewVisitHistory from '../components/CustomerOverviewVisitHistory'; // Importer den nye komponenten
import CustomerOverviewEditEqpmt from '../components/CustomerOverviewEditEqpmt';
import './CustomerOverview.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext
import { FaFilePdf, FaFileExcel  } from "react-icons/fa";


Modal.setAppElement('#root');

const CustomerOverview = () => {
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext
  const { kundeId } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditAgrmtModalOpen, setIsEditAgrmtModalOpen] = useState(false);
  const [isAddServiceAddressModalOpen, setIsAddServiceAddressModalOpen] = useState(false);
  const [agreements, setAgreements] = useState({ active: [], inactive: [] });
  const [expandedAgreements, setExpandedAgreements] = useState([]);
  const [addresses, setAddresses] = useState({});
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [agreementDocuments, setAgreementDocuments] = useState({});
  const [isEditEqpmtModalOpen, setIsEditEqpmtModalOpen] = useState(false); // Ny tilstan


  useEffect(() => {
    if (!kundeId) {
      navigate('/customers');
    } else {
      fetchCustomerData(kundeId);
      fetchAgreements(kundeId);
    }
  }, [kundeId, navigate]);

  const fetchCustomerData = async (kundeId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustOverview/${kundeId}/?token=${token}`);
      if (response.data.status === 'success') {
        setCustomer(response.data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const fetchAgreements = async (kundeId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustomerAllAgreements/${kundeId}/?token=${token}`);
      if (response.data.status === 'success') {
        const activeAgreements = response.data.data.filter(agreement => agreement.agrmt_status === "Open");
        const inactiveAgreements = response.data.data.filter(agreement => agreement.agrmt_status !== "Open");
        setAgreements({ active: activeAgreements, inactive: inactiveAgreements });
  
        // 🔥 Hent document_id og adresser for hver avtale
        response.data.data.forEach(agreement => {
          if (agreement.avtale_id) {
            fetchAgreementDocumentId(agreement.avtale_id); // ✅ Henter kun én document_id per avtale
            fetchAddresses(agreement.avtale_id); // ✅ Henter ALLE behandlingsadr_id til avtalen
          }
        });
      }
    } catch (error) {
      console.error('Error fetching agreements:', error);
    }
  };

  const fetchAddresses = async (avtale_id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustomerAllAgreementsBehadr/${avtale_id}/?token=${token}`);
      if (response.data.status === 'success') {
        setAddresses(prevAddresses => ({
          ...prevAddresses,
          [avtale_id]: response.data.data // ✅ Lagre ALLE behandlingsadr_id under riktig avtale_id
        }));
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };
  

  const fetchAgreementDocumentId = async (avtale_id) => {
    if (!avtale_id) return; // ✅ Unngår unødvendige API-kall
    
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustOverviewAgrmtDocId/${avtale_id}/?token=${token}`);
      if (response.data.status === 'success' && response.data.data.length > 0) {
        const docData = response.data.data[0];
        
        // 🔥 Bruk agreement_doc_id hvis document_id er null
        const documentId = docData.document_id !== null ? docData.document_id : docData.agreement_doc_id;
        
        setAgreementDocuments(prevDocs => ({
          ...prevDocs,
          [avtale_id]: documentId // ✅ Lagre riktig dokument-ID
        }));
  
        console.log("📂 Stored document ID for agreement:", avtale_id, " → ", documentId); // 🔍 LOGG
      } else {
        console.log("⚠️ No document found for agreement:", avtale_id); // 🔍 LOGG
        setAgreementDocuments(prevDocs => ({
          ...prevDocs,
          [avtale_id]: null
        }));
      }
    } catch (error) {
      console.error('Error fetching agreement document ID:', error);
    }
  };
  
  

  const downloadAgreementDocument = async (documentId) => {
    if (!documentId) {
      console.error("❌ documentId is undefined. Cannot download file."); // 🔍 LOGG FEIL
      return;
    }
  
    console.log("📥 Downloading document with ID:", documentId); // 🔍 LOGG
  
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getFileAgreementDocument/${documentId}?token=${token}`, {
        responseType: 'blob' // 🔥 Viktig for filnedlasting
      });
  
      if (response.status === 200) {
        console.log("✅ File downloaded successfully."); // 🔍 LOGG
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `AgreementDocument_${documentId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("❌ Error: Could not download document. Server responded with:", response.status);
      }
    } catch (error) {
      console.error('❌ Error downloading agreement document:', error);
    }
  };
  
  
  
  
  const toggleAgreement = (id) => {
    setExpandedAgreements((prevExpanded) =>
      prevExpanded.includes(id)
        ? prevExpanded.filter((agreementId) => agreementId !== id)
        : [...prevExpanded, id]
    );
  };

  const navigateToServiceAddress = (behandlingsadr_id) => {
    navigate(`/serviceaddress/${behandlingsadr_id}`, { state: { from: `/customeroverview/${customer.kunde_id}`, fromName: translations['customerOverview'] || 'Customer Overview' } });
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = (action, newPhone, newEmail, newContactPerson, newCustomerCat) => {
    if (action === 'cancel' && customer) {
      const isChanged =
        customer.telefon !== newPhone ||
        customer.epost !== newEmail ||
        customer.kontakt !== newContactPerson ||
        customer.customer_cat_id !== newCustomerCat;
  
      if (isChanged) {
        const confirmClose = window.confirm(translations['discardChanges'] || "You have unsaved changes. Do you want to discard them?");
        if (!confirmClose) return;
      }
    }
  
    setIsEditModalOpen(false);
  };  

  const openEditAgrmtModal = (agreement) => {
    setSelectedAgreement(agreement);
    setIsEditAgrmtModalOpen(true);
  };

  const closeEditAgrmtModal = (action, newAgrmtName, newWorkDescr, newNotes) => {
    if (action === 'cancel' && selectedAgreement) {
      const isChanged =
        selectedAgreement.agrmt_name !== newAgrmtName ||
        selectedAgreement.work_descr !== newWorkDescr ||
        selectedAgreement.notat !== newNotes;
  
      if (isChanged) {
        const confirmClose = window.confirm(translations['discardChanges'] || "You have unsaved changes. Do you want to discard them?");
        if (!confirmClose) return;
      }
    }
  
    setIsEditAgrmtModalOpen(false);
  };
  

  const openAddServiceAddressModal = (agreement) => {
    setSelectedAgreement(agreement);
    setIsAddServiceAddressModalOpen(true);
  };

  const closeAddServiceAddressModal = () => {
    setIsAddServiceAddressModalOpen(false);
  };

  const openEditEqpmtModal = (agreement) => {
    setSelectedAgreement(agreement);
    setIsEditEqpmtModalOpen(true);
  };
  
  const closeEditEqpmtModal = () => {
    setIsEditEqpmtModalOpen(false);
  };

  return (
    <MainLayout title={translations['customerOverview'] || "Customer Overview"}>
      <div className="custom-container">
        {customer ? (
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-info">
                <h2>{customer.navn}</h2>
                <p>{customer.adresse}</p>
                <p>{customer.postnr} {customer.poststed}</p>
                <p><strong>{translations['extCustNo'] || 'Ext. Cust No:'}</strong> <span>{customer.kundenr}</span></p>
                <p><strong>{translations['contactPerson'] || 'Contact Person:'}</strong> <span>{customer.kontakt}</span></p>
                <p><strong>{translations['phone'] || 'Phone:'}</strong> <span>{customer.telefon}</span></p>
                <p><strong>{translations['email'] || 'Email:'}</strong> <span>{customer.epost}</span></p>
                <p><strong>{translations['customerCategory'] || 'Customer Category:'}</strong> <span>{customer.customer_cat}</span></p>
              </div>
            </div>
            <button className="button edit-button" onClick={openEditModal}>{translations['edit'] || 'Edit'}</button>
            <button className="button create-agreement-button" onClick={() => navigate(`/agreement-new/${customer.kunde_id}`)}>
              {translations['createAgreement'] || 'Create Agreement'}
            </button>
            
            <div className="custom-accordion-section">
              <button className="custom-accordion-button" onClick={() => toggleAgreement('agreements')}>{translations['agreements'] || 'Agreements'}</button>
              {expandedAgreements.includes('agreements') && (
                <div className="custom-accordion-content">
                {agreements.active.map((agreement) => (
                  <div key={agreement.avtale_id} className="custom-accordion-item">
                    <button
                      className="custom-accordion-button"
                      onClick={() => toggleAgreement(agreement.avtale_id)}
                    >
                      {agreement.agrmt_name
                        ? agreement.agrmt_name
                        : `${translations['agreement']} #${agreement.avtale_id}`} 
                      {' - '}
                      {agreement.dato_start}
                    </button>
              
                    {expandedAgreements.includes(agreement.avtale_id) && (
                      <div className="custom-accordion-item-content">
                        
                        {/** --- GRID-OPPSETT --- **/}
                        <div className="agreement-details-grid">
                          {/* Row 1: Status + PDF-/Excel-ikon */}
                          <div className="grid-label">
                            <strong>{translations['status'] || 'Status:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.agrmt_status}
                          </div>
                          <div className="grid-icon pdf-icon-container">
                            {agreementDocuments[agreement.avtale_id] ? (
                              <FaFilePdf
                                className="pdf-icon"
                                onClick={() => downloadAgreementDocument(agreementDocuments[agreement.avtale_id])}
                                title={translations['downloadAgreement'] || 'Download Agreement Document'}
                              />
                            ) : (
                              <FaFileExcel
                                className="no-document-icon"
                                title={translations['noDocumentAvailable'] || 'No Document Available'}
                              />
                            )}
                          </div>
              
                          {/* Row 2: Pest/Treatment */}
                          <div className="grid-label">
                            <strong>{translations['pestTreatment'] || 'Pest/Treatment:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.behandling.join(', ')}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 3: Equipment Type */}
                          <div className="grid-label">
                            <strong>{translations['equipmentType'] || 'Equipment Type:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.utstyr.map(item => `${item.utstyr} (${item.antall})`).join(', ')}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 4: Agreement Name */}
                          <div className="grid-label">
                            <strong>{translations['agreementName'] || 'Agreement Name:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.agrmt_name}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 5: Work Description */}
                          <div className="grid-label">
                            <strong>{translations['workDescription'] || 'Work Description:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.work_descr}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 6: Notes */}
                          <div className="grid-label">
                            <strong>{translations['notes'] || 'Notes:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.notat}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 7: Visits per year */}
                          <div className="grid-label">
                            <strong>{translations['visitsPerYear'] || 'Number of Visits per Year:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.kontroller}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Row 8: Visits this year */}
                          <div className="grid-label">
                            <strong>{translations['visitsThisYear'] || 'Number of Visits This Year:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.visits}
                          </div>
                          <div className="grid-icon"></div>
                        </div>
                        
                        {/** --- HANDLING-KNAPPER --- **/}
                        <div className="agreement-actions">
                          <button
                            className="button edit-agreement-button"
                            onClick={() => openEditAgrmtModal(agreement)}
                          >
                            {translations['editAgreement'] || 'Edit Agreement'}
                          </button>
                          <button
                            className="button edit-equipment-button"
                            onClick={() => openEditEqpmtModal(agreement)}
                          >
                            {translations['Equipment'] || 'Equipment'}
                          </button>
                          {addresses[agreement.avtale_id] && addresses[agreement.avtale_id].map(address => (
                            <button
                              key={address.behandlingsadr_id}
                              className="custom-address-button"
                              onClick={() => navigateToServiceAddress(address.behandlingsadr_id)}
                            >
                              {address.behandlingsadr}, {address.postnr} {address.poststed}
                            </button>
                          ))}
                          <button
                            className="button add-service-address-button"
                            onClick={() => openAddServiceAddressModal(agreement)}
                          >
                            {translations['addServiceAddress'] || 'Add Service Address'}
                          </button>
                        </div>
                        {/** Slutt på .custom-accordion-item-content */}
                      </div>
                    )}
                  </div>
                ))}
              </div>              
              )}
            </div>

            <div className="custom-accordion-section">
              <button className="custom-accordion-button" onClick={() => toggleAgreement('inactiveAgreements')}>
                {translations['inactiveAgreements'] || 'Agreements (Inactive)'}
              </button>
              {expandedAgreements.includes('inactiveAgreements') && (
                <div className="custom-accordion-content">
                {agreements.inactive.map((agreement) => (
                  <div key={agreement.avtale_id} className="custom-accordion-item">
                    <button
                      className="custom-accordion-button"
                      onClick={() => toggleAgreement(agreement.avtale_id)}
                    >
                      {agreement.agrmt_name
                        ? agreement.agrmt_name
                        : `${translations['agreement']} #${agreement.avtale_id}`} 
                      {' - '}
                      {agreement.dato_start}
                    </button>
              
                    {expandedAgreements.includes(agreement.avtale_id) && (
                      <div className="custom-accordion-item-content">
              
                        {/* GRID-OPPSETT FOR INACTIVE-AGREEMENT */}
                        <div className="agreement-details-grid">
                          {/* Rad 1: Status + PDF/Excel-ikon */}
                          <div className="grid-label">
                            <strong>{translations['status'] || 'Status:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.agrmt_status}
                          </div>
                          <div className="grid-icon pdf-icon-container">
                            {agreementDocuments[agreement.avtale_id] ? (
                              <FaFilePdf
                                className="pdf-icon"
                                onClick={() => downloadAgreementDocument(agreementDocuments[agreement.avtale_id])}
                                title={translations['downloadAgreement'] || 'Download Agreement Document'}
                              />
                            ) : (
                              <FaFileExcel
                                className="no-document-icon"
                                title={translations['noDocumentAvailable'] || 'No Document Available'}
                              />
                            )}
                          </div>
              
                          {/* Rad 2: Pest/Treatment */}
                          <div className="grid-label">
                            <strong>{translations['pestTreatment'] || 'Pest/Treatment:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.behandling.join(', ')}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 3: Equipment Type */}
                          <div className="grid-label">
                            <strong>{translations['equipmentType'] || 'Equipment Type:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.utstyr.map(item => `${item.utstyr} (${item.antall})`).join(', ')}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 4: Agreement Name */}
                          <div className="grid-label">
                            <strong>{translations['agreementName'] || 'Agreement Name:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.agrmt_name}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 5: Work Description */}
                          <div className="grid-label">
                            <strong>{translations['workDescription'] || 'Work Description:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.work_descr}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 6: Notes */}
                          <div className="grid-label">
                            <strong>{translations['notes'] || 'Notes:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.notat}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 7: Visits per year */}
                          <div className="grid-label">
                            <strong>{translations['visitsPerYear'] || 'Number of Visits per Year:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.kontroller}
                          </div>
                          <div className="grid-icon"></div>
              
                          {/* Rad 8: Visits this year */}
                          <div className="grid-label">
                            <strong>{translations['visitsThisYear'] || 'Number of Visits This Year:'}</strong>
                          </div>
                          <div className="grid-value">
                            {agreement.visits}
                          </div>
                          <div className="grid-icon"></div>
                        </div>
                        {/* SLUTT PÅ GRID-OPPSETT */}        
              
                        {/* Adresser og ev. handling-knapper for Inactive Agreements */}
                        <div className="agreement-actions">
                          {addresses[agreement.avtale_id] && addresses[agreement.avtale_id].map(address => (
                            <button
                              key={address.behandlingsadr_id}
                              className="custom-address-button"
                              onClick={() => navigateToServiceAddress(address.behandlingsadr_id)}
                            >
                              {address.behandlingsadr}, {address.postnr} {address.poststed}
                            </button>
                          ))}
                        </div>
              
                      </div>
                    )}
                  </div>
                ))}
              </div>              
              )}
            </div>
            
            {/* Legg til Visit History Customer-komponenten */}
            <CustomerOverviewVisitHistory 
              kundeId={kundeId} 
              token={localStorage.getItem('token')} 
            />
            
          </div>
        ) : (
          <p>{translations['loading'] || 'Loading...'}</p>
        )}

        <CustomerOverviewEditCustomer
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          customer={customer}
          onUpdate={() => fetchCustomerData(kundeId)}  // Kaller fetchCustomerData med kundeId for å oppdatere dataene
        />

        <CustomerOverviewEditAgrmt
          isOpen={isEditAgrmtModalOpen}
          onRequestClose={closeEditAgrmtModal}
          agreement={selectedAgreement}
          onUpdate={() => fetchAgreements(kundeId)} // Kaller fetchAgreements med kundeId for å oppdatere avtale-dataene
        />

        <CustomerOverviewAddServiceAddress
          isOpen={isAddServiceAddressModalOpen}
          onRequestClose={closeAddServiceAddressModal}
          agreement={selectedAgreement}  // Passer den valgte avtalen til modalen
          onUpdate={() => fetchAgreements(kundeId)} // Oppdaterer avtale-dataene når en ny serviceadresse legges til
        />

        <CustomerOverviewEditEqpmt
          isOpen={isEditEqpmtModalOpen}
          onRequestClose={closeEditEqpmtModal}
          agreement={selectedAgreement}
          onUpdate={() => fetchAgreements(kundeId)} // Kaller fetchAgreements med kundeId for å oppdatere avtale-dataene
        />

      </div>
      
    </MainLayout>
  );
};

export default CustomerOverview;
