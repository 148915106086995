import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient';
import config from '../config';
import { FaTrashAlt } from 'react-icons/fa';
import './CustomerOverviewEditEqpmt.css';
import { LanguageContext } from '../contexts/LanguageContext';

Modal.setAppElement('#root');

const CustomerOverviewEditEqpmt = ({ isOpen, onRequestClose, agreement, onUpdate}) => {
  const [equipment, setEquipment] = useState([]);
  const [availableEquipment, setAvailableEquipment] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [toDelete, setToDelete] = useState([]);
  const [newEquipment, setNewEquipment] = useState([]);
  const { translations } = useContext(LanguageContext);

  useEffect(() => {
    if (isOpen && agreement) {
      fetchEquipment();
      fetchAvailableEquipment();
    }
  }, [isOpen, agreement]);

  const fetchEquipment = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.get(
        `${config.apiUrl}/getUtstyrInkNew/${agreement.avtale_id}?token=${token}`
      );
      if (response.data.status === 'success') {
        setEquipment(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching equipment:', error);
    }
  };

  const fetchAvailableEquipment = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await apiClient.get(`${config.apiUrl}/getUtstyr?token=${token}`);
      if (response.data.status === 'success') {
        setAvailableEquipment(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching available equipment:', error);
    }
  };

  const handleQuantityChange = (utstyr_id, value) => {
    setEquipment((prev) =>
      prev.map((item) =>
        item.utstyr_id === utstyr_id
          ? { ...item, antall: Math.max(0, parseInt(value, 10) || 0) }
          : item
      )
    );
  };

  const handleDelete = (utstyr_id) => {
    setToDelete((prev) => [...prev, utstyr_id]);
    setEquipment((prev) => prev.filter((item) => item.utstyr_id !== utstyr_id));
  };

  const handleAddEquipment = () => {
    if (!selectedEquipment) return;
    const selected = availableEquipment.find(
      (item) => item.utstyr_id === parseInt(selectedEquipment, 10)
    );
    if (selected) {
      const newItem = {
        utstyr_id: selected.utstyr_id,
        avtale_id: agreement.avtale_id,
        antall: 1,
        utstyr: selected.utstyr,
      };
      setEquipment((prev) => [...prev, newItem]);
      setNewEquipment((prev) => [...prev, newItem]);
      setSelectedEquipment("");
    }
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');

    for (let utstyr_id of toDelete) {
      await apiClient.delete(`${config.apiUrl}/delUtstyrAvtale`, {
        data: {
          token,
          utstyr_id,
          avtale_id: agreement.avtale_id,
        },
      });
    }

    for (let item of equipment) {
      await apiClient.put(`${config.apiUrl}/updUtstyrInk`, {
        token,
        utstyr_id: item.utstyr_id,
        avtale_id: agreement.avtale_id,
        antall: item.antall,
      });
    }

    for (let item of newEquipment) {
      const antall = equipment.find((eq) => eq.utstyr_id === item.utstyr_id)?.antall || 1;
      await apiClient.post(`${config.apiUrl}/addUtstyrInk`, {
        token,
        utstyr_id: item.utstyr_id,
        avtale_id: agreement.avtale_id,
        antall: antall,
      });
    }

    onRequestClose();
    onUpdate();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <h2>{translations['editEquipmentTitle'] || 'Edit Equipment'}</h2>
      <table className="equipment-table">
        <thead>
          <tr>
            <th>{translations['equipmentName'] || 'Equipment Name'}</th>
            <th>{translations['quantity'] || 'Quantity'}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {equipment.map((item) => (
            <tr key={item.utstyr_id}>
              <td>{item.utstyr}</td>
              <td>
                <input
                  className="quantity-input"
                  type="number"
                  value={item.antall}
                  onChange={(e) => handleQuantityChange(item.utstyr_id, e.target.value)}
                  min="0"
                />
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.utstyr_id)}
                >
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>

      </table>

      <div className="add-equipment-section">
        <select
          className="equipment-dropdown"
          value={selectedEquipment}
          onChange={(e) => setSelectedEquipment(e.target.value)}
        >
          <option value="">{translations['selectEquipment'] || 'Select Equipment'}</option>
          {availableEquipment.map((item) => (
            <option
              key={item.utstyr_id}
              value={item.utstyr_id}
              disabled={equipment.some((eq) => eq.utstyr_id === item.utstyr_id)}
            >
              {item.utstyr}
            </option>
          ))}
        </select>
        <button
          className="add-equipment-button"
          onClick={handleAddEquipment}
        >
          {translations['addButton'] || 'Add'}
        </button>
      </div>

      <div className="modal-buttons">
        <button className="cancel-button" onClick={onRequestClose}>
          {translations['cancelButton'] || 'Cancel'}
        </button>
        <button className="save-button" onClick={handleSave}>
          {translations['saveButton'] || 'Save'}
        </button>
      </div>
    </Modal>
  );
};

export default CustomerOverviewEditEqpmt;
