import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import { FaLayerGroup } from 'react-icons/fa6';

const Map = forwardRef(({ initialAddress }, ref) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const [currentLayer, setCurrentLayer] = useState('openstreetmap');
  const isMapLoaded = useRef(false);

  useEffect(() => {
    if (mapContainer.current) {
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {
            'carto': {
              type: 'raster',
              tiles: ['https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'],
              tileSize: 256,
              attribution: '&copy; <a href="https://carto.com/">CARTO</a>'
            },
            'openstreetmap': {
              type: 'raster',
              tiles: [
                'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
              ],
              tileSize: 256,
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            },
            'kartverket': {
              type: 'raster',
              tiles: [
                'https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png'
              ],
              tileMatrixSet: 'webmercator',
              tileSize: 256,
              attribution: '&copy; <a href="https://www.kartverket.no">Kartverket</a>'
            }
          },          
          layers: [
            {
              id: 'carto-layer',
              type: 'raster',
              source: 'carto',
              minzoom: 0,
              maxzoom: 24,
              layout: { visibility: 'visible' }
            },
            {
              id: 'osm-layer',
              type: 'raster',
              source: 'openstreetmap',
              minzoom: 0,
              maxzoom: 24,
              layout: { visibility: 'none' }
            },
            {
              id: 'kartverket-layer',
              type: 'raster',
              source: 'kartverket',
              minzoom: 0,
              maxzoom: 19,
              layout: { visibility: 'none' }
            }
          ]
        },
        center: [10.7522, 59.9139], // Senter koordinater (f.eks. Oslo)
        zoom: 17,
        maxZoom: 20 // Begrens zoomnivået til 19
      });

      mapInstance.current = map;

      map.on('load', () => {
        isMapLoaded.current = true;
        if (initialAddress) {
          geocodeAndCenterMap(initialAddress);
        }
      });

      // Legg til GeolocateControl for å finne brukerens lokasjon
      const geolocateControl = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      });

      map.addControl(geolocateControl, 'top-right');

      // Legg til NavigationControl for zoom og rotasjon
      const navControl = new maplibregl.NavigationControl();
      map.addControl(navControl, 'top-right');

      // Bruk MaplibreGeocoder med riktig maplibregl-referanse
      const geocoder = new MaplibreGeocoder({
        maplibregl: maplibregl,  // Viktig: Legg til referansen til maplibregl her
        placeholder: 'Search for places',
        forwardGeocode: async (config) => {
          const baseUrl = 'https://nominatim.openstreetmap.org/search';
          const params = new URLSearchParams({
            q: config.query,
            format: 'json',
            addressdetails: 1,
            polygon_geojson: 0,
            limit: 5 // Hent opptil 5 resultater for bedre brukeropplevelse
          });

          try {
            const response = await fetch(`${baseUrl}?${params}`);
            if (!response.ok) {
              throw new Error(`Feil ved henting av geokodingdata: ${response.statusText}`);
            }

            const data = await response.json();

            // Sjekk om dataene er gyldige og inneholder resultater
            if (!data || data.length === 0) {
              console.error('Ingen treff funnet for søket.');
              return { features: [] };
            }

            // Mapp dataene til GeoJSON-formatet som geocoder krever
            const features = data.map((item) => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [parseFloat(item.lon), parseFloat(item.lat)]
              },
              place_name: item.display_name,
              properties: item
            }));

            return {
              features: features
            };

          } catch (error) {
            console.error('Feil ved geokoding: ', error);
            return { features: [] }; // Returner tomt resultat ved feil
          }
        }
      });

      map.addControl(geocoder, 'top-left');

      // Tilpasset kontroll for å velge lag
      class LayerSwitcherControl {
        onAdd(map) {
          this.map = map;
          this.container = document.createElement('div');
          this.container.className = 'maplibregl-ctrl maplibregl-ctrl-group';

          this.button = document.createElement('button');
          this.button.type = 'button';
          this.button.innerHTML = `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 10L12 15L21 10L12 5L3 10Z" style="stroke: black;" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 15L12 20L21 15" style="stroke: black;" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `;

          this.button.onclick = () => {
            toggleLayer();
          };

          this.container.appendChild(this.button);
          return this.container;
        }

        onRemove() {
          this.container.parentNode.removeChild(this.container);
          this.map = undefined;
        }
      }

      // Legg til den tilpassede kontrollen
      const layerSwitcherControl = new LayerSwitcherControl();
      map.addControl(layerSwitcherControl, 'top-right');

      return () => map.remove();
    }
  }, []);

  useEffect(() => {
    if (isMapLoaded.current && initialAddress) {
      geocodeAndCenterMap(initialAddress);
    }
  }, [initialAddress, isMapLoaded.current]);

  useEffect(() => {
    if (isMapLoaded.current && mapInstance.current) {
      if (currentLayer === 'openstreetmap') {
        mapInstance.current.setLayoutProperty('osm-layer', 'visibility', 'visible');
        mapInstance.current.setLayoutProperty('kartverket-layer', 'visibility', 'none');
      } else if (currentLayer === 'kartverket') {
        mapInstance.current.setLayoutProperty('osm-layer', 'visibility', 'none');
        mapInstance.current.setLayoutProperty('kartverket-layer', 'visibility', 'visible');
      }
    }
  }, [currentLayer]);

  const toggleLayer = () => {
    setCurrentLayer((prevLayer) =>
      prevLayer === 'openstreetmap' ? 'kartverket' : 'openstreetmap'
    );
  };

  const geocodeAndCenterMap = async (address) => {
    const baseUrl = 'https://nominatim.openstreetmap.org/search';
    const params = new URLSearchParams({
      q: address,
      format: 'json',
      addressdetails: 1,
      polygon_geojson: 0,
      limit: 1 // Hent kun ett resultat for å sikre rask respons
    });

    try {
      const response = await fetch(`${baseUrl}?${params}`);
      if (!response.ok) {
        throw new Error(`Feil ved henting av geokodingdata: ${response.statusText}`);
      }

      const data = await response.json();
      if (data && data.length > 0) {
        const { lon, lat } = data[0];
        mapInstance.current.setCenter([parseFloat(lon), parseFloat(lat)]);
        mapInstance.current.setZoom(15); // Zoom til passende nivå etter geokoding
      } else {
        console.error('Ingen resultater funnet for adressen');
      }
    } catch (error) {
      console.error('Feil ved geokoding: ', error);
    }
  };

  useImperativeHandle(ref, () => ({
    getMap: () => mapInstance.current
  }));

  return <div ref={mapContainer} style={{ width: '600px', height: '400px', maxWidth: '600px', maxHeight: '400px' }} />;

});

export default Map;
