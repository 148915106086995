import React, { useState, useEffect, useCallback, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for axios
import Modal from 'react-modal';
import MainLayout from '../components/MainLayout';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext
import { useToast } from '../contexts/ToastContext'; // Import the useToast hook
import packageInfo from '../../package.json';
import ReactMarkdown from 'react-markdown';
import './MyProfile.css';


Modal.setAppElement('#root');

const MyProfile = () => {
  const { translations, changeLanguage } = useContext(LanguageContext); // Use the LanguageContext
  const notify = useToast(); // Get the notify function from ToastContext
  const [userData, setUserData] = useState({});
  const [startAddress, setStartAddress] = useState('');
  const [endAddress, setEndAddress] = useState('');
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [showChangelogModal, setShowChangelogModal] = useState(false);
  const [changelog, setChangelog] = useState('');
  const userId = localStorage.getItem('userid');
  const token = localStorage.getItem('token');

  const loadProfileData = useCallback(() => {
    apiClient.get(`${config.apiUrl}/getMyProfile/${userId}?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          const data = response.data.data[0];
          setUserData(data);
          setStartAddress(localStorage.getItem('start_address') || data.start_position);
          setEndAddress(localStorage.getItem('end_address') || data.end_position);
          setSelectedLanguage(localStorage.getItem('language_id') || data.language_id);
        }
      }).catch(error => {
        console.error('Error fetching profile data:', error);
        notify('error', 'Error fetching profile data'); // Use notify instead of toast.error
      });
  }, [userId, token, notify]);

  const loadLanguages = useCallback(() => {
    apiClient.get(`${config.apiUrl}/getLanguage?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setLanguages(response.data.data);
        }
      }).catch(error => {
        console.error('Error fetching languages:', error);
        notify('error', 'Error fetching languages'); // Use notify instead of toast.error
      });
  }, [token, notify]);

  useEffect(() => {
    if (userId && token) {
      loadProfileData();
      loadLanguages();
    }
  }, [userId, token, loadLanguages, loadProfileData]);

  const handleResetBadLogon = () => {
    apiClient.post(`${config.apiUrl}/updResetBadLogon/${userId}?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          notify('success', response.data.message); // Use notify instead of toast.success
          loadProfileData(); // Reload profile data to update login attempts
        } else {
          notify('error', response.data.message); // Use notify instead of toast.error
        }
      }).catch(error => {
        notify('error', 'Error resetting bad logon attempts'); // Use notify instead of toast.error
      });
  };

  const handleSaveChanges = () => {
    apiClient.post(`${config.apiUrl}/updLanguage?token=${token}`, {
      id: userId,
      start_position: startAddress,
      end_position: endAddress,
      language_id: selectedLanguage
    }).then(response => {
      if (response.data.status === 'success') {
        localStorage.setItem('start_address', startAddress);
        localStorage.setItem('end_address', endAddress);
        localStorage.setItem('language_id', selectedLanguage);
        changeLanguage(selectedLanguage); // Update app language
        notify('success', response.data.message); // Use notify instead of toast.success
      } else {
        notify('error', response.data.message); // Use notify instead of toast.error
      }
    }).catch(error => {
      notify('error', 'Error saving changes'); // Use notify instead of toast.error
    });
  };

  const handleLogout = () => {
    // Lagre verdien av rememberMe
    const rememberMeValue = localStorage.getItem('rememberMe');

    // Tøm hele localStorage
    localStorage.clear();

    // Sett tilbake rememberMe verdien
    if (rememberMeValue !== null) {
      localStorage.setItem('rememberMe', rememberMeValue);
    }
    notify('success', 'Logged out successfully'); // Use notify instead of toast.success
    setTimeout(() => {
      window.location.href = '/';
    }, 1000); // Redirect to home after showing the message
  };

  const handleChangePassword = () => {
    setShowModal(true);
  };

  const handleSubmitPasswordChange = () => {
    if (password !== verifyPassword) {
      notify('error', 'Passwords do not match'); // Use notify instead of toast.error
      return;
    }

    apiClient.post(`${config.apiUrl}/updPassword?token=${token}`, {
      user_id: userId,
      password: password,
    }).then(response => {
      if (response.data.status === 'success') {
        notify('success', response.data.msg); // Use notify instead of toast.success
        setShowModal(false);
        setPassword(''); // Clear the password field
        setVerifyPassword(''); // Clear the verify password field
      } else {
        notify('error', response.data.msg); // Use notify instead of toast.error
      }
    }).catch(error => {
      notify('error', 'Error changing password'); // Use notify instead of toast.error
    });
  };

  const clearSiteData = () => {
    // Clear localStorage and sessionStorage
    localStorage.clear();
    sessionStorage.clear();

    // Clear cookies
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });

    // Clear IndexedDB
    if (window.indexedDB && indexedDB.databases) {
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          indexedDB.deleteDatabase(db.name);
        });
      });
    }

    // Clear Cache Storage
    if (window.caches) {
      caches.keys().then(keyList => {
        return Promise.all(keyList.map(key => {
          return caches.delete(key);
        }));
      });
    }

    notify('success', 'Cache and site data cleared!'); // Use notify instead of toast.success
    setTimeout(() => {
      window.location.href = '/';
    }, 1000); // Redirect to home after clearing data
  };

  const loadChangelog = () => {
    fetch('/changelog.md')
      .then((response) => response.text())
      .then((text) => setChangelog(text));
  };
  
  const handleChangelogOpen = () => {
    loadChangelog();
    setShowChangelogModal(true);
  };

  return (
    <MainLayout>
      <h1>{translations.profile}</h1>
      <div className="profile-info">
        <img src="/assets/avatar.png" alt="Profile Avatar" className="profile-avatar" />
        <h2>{userData.navn}</h2>
        <p>{userData.username}</p>
        <button className="button" onClick={handleChangePassword}>{translations.changePassword}</button>
      </div>
      <div className="profile-section">
        <h3>{translations.failedLoginAttempts}:</h3>
        <p>{userData.logon_attempt}</p>
        <button className="button" onClick={handleResetBadLogon}>{translations.resetCounter}</button>
      </div>
      <div className="profile-section">
        <h3>{translations.routePlanningAddresses}:</h3>
        <label>
          {translations.startAddress}:
          <input
            type="text"
            value={startAddress}
            className="profile-address-input"
            onChange={(e) => setStartAddress(e.target.value)}
          />
        </label>
        <label>
          {translations.endAddress}:
          <input
            type="text"
            value={endAddress}
            className="profile-address-input"
            onChange={(e) => setEndAddress(e.target.value)}
          />
        </label>
      </div>
      <div className="profile-section">
        <h3>{translations.selectLanguage}:</h3>
        <select
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          {languages.map(lang => (
            <option key={lang.language_id} value={lang.language_id}>
              {lang.language}
            </option>
          ))}
        </select>
      </div>
      <button className="button" onClick={handleSaveChanges}>{translations.saveChanges}</button>
      <button className="button logout-button" onClick={handleLogout}>{translations.logout}</button>
      
      
      <div className="app-version">
        <h3>{translations.appVersion}:</h3>
        <p>{packageInfo.version}</p>
        <button className="button" onClick={handleChangelogOpen}>{translations.viewChangelog || 'View Changelog'}</button>
        <button className="button clear-cache-button" onClick={clearSiteData}>{translations.clearCache}</button>
      </div>


      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Change Password"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-content">
          <h2>{translations.changePassword}</h2>
          <label>
            {translations.newPassword}:
            <div className="password-input-container">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </label>
          <label>
            {translations.verifyPassword}:
            <div className="password-input-container">
              <input
                type="password"
                value={verifyPassword}
                onChange={(e) => setVerifyPassword(e.target.value)}
              />
            </div>
          </label>
          <button className="button" onClick={handleSubmitPasswordChange}>{translations.submit}</button>
          <button className="button cancel-button" onClick={() => {
            setShowModal(false);
            setPassword(''); // Clear the password field
            setVerifyPassword(''); // Clear the verify password field
          }}>{translations.cancel}</button>
        </div>
      </Modal>
      <Modal
        isOpen={showChangelogModal}
        onRequestClose={() => setShowChangelogModal(false)}
        contentLabel="Changelog"
        className="cl-modal"
        overlayClassName="overlay"
      > 
        <div className="cl-modal-content">
          
          <ReactMarkdown>{changelog}</ReactMarkdown>
          <button className="button cancel-button" onClick={() => setShowChangelogModal(false)}>
            {translations.close || 'Lukk'}
          </button>
        </div>
      </Modal>  

    </MainLayout>
  );
};

export default MyProfile;
