import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import { FaCamera, FaFastBackward } from 'react-icons/fa';
import apiClient from '../../api/apiClient';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress';
import EditImageModal from '../../components/EditImageModal';
import CropImageModal from '../../components/CropImageModal'; // ✅ Ny modal
import { useToast } from '../../contexts/ToastContext';
import OppdragInfoBanner from '../../components/OppdragInfoBanner';
import { LanguageContext } from '../../contexts/LanguageContext';

const Photos = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [images, setImages] = useState([]); // Eksisterende bilder fra API
  const [newImages, setNewImages] = useState([]); // Bilder som ikke er lagret
  const [isLoading, setIsLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [imageToEdit, setImageToEdit] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const fileInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast();
  const { translations } = useContext(LanguageContext);
  const [showCropModal, setShowCropModal] = useState(false);  // ✅ Styrer beskjæringsmodal
  const [imageToCrop, setImageToCrop] = useState(null);  // ✅ Holder bildet som skal beskjæres

  

  // Hent bilder fra API
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getOppdrag_bilde_id/${oppdrag_id}/?token=${token}`);
        if (response.status !== 200) throw new Error('Failed to fetch image IDs');

        const oppdragBildeIds = response.data.data.map((item) => item.oppdrag_bilde_id);
        if (oppdragBildeIds.length === 0) {
          setImages([]);
          return;
        }

        const imagePromises = oppdragBildeIds.map(async (id) => {
          const imageResponse = await apiClient.get(`${config.apiUrl}/getPhotosOppdrag/${id}/?token=${token}`);
          const commentResponse = await apiClient.get(`${config.apiUrl}/getPhotosOppdragComment/${id}/?token=${token}`);

          if (imageResponse.status !== 200 || commentResponse.status !== 200) {
            throw new Error('Failed to fetch image or comment');
          }

          return {
            id,
            src: imageResponse.data.data.bilde,
            comment: commentResponse.data.data.kommentar || '',
            hasChanged: false,
          };
        });

        const fetchedImages = await Promise.all(imagePromises);
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
        notify('error', translations.fetchImagesError || 'Failed to fetch images.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [oppdrag_id, token, notify, translations]);

  // Håndter filopplasting
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length + newImages.length > 15) {
      notify('error', translations.maxImages || 'can only upload up to 15 images.');
      return;
    }
  
    // 🚀 Åpner CropImageModal med det valgte bildet
    setImageToCrop(files[0]); 
    setShowCropModal(true);   
  };
  
  const handleCroppedImage = (croppedImage) => {
    setNewImages((prevNewImages) => [...prevNewImages, { src: croppedImage, comment: "" }]); 
  };

  // Håndter redigering av bilder
  const handleEditImage = (image, index, isNewImage) => {
    console.log("✏️ Åpner redigeringsmodal for bilde:", image);
  
    setImageToEdit(null); // 🚀 Nullstill for å sikre riktig lasting
    setTimeout(() => {
      setImageToEdit(image);  // ✅ Sender bildet til EditImageModal
      setEditIndex({ index, isNewImage });
      setShowEditModal(true);
    }, 50);
  };
  
  

  const handleSaveEditedImage = (editedImage) => {
    console.log("🔄 Oppdaterer state i Photos.js med redigert bilde:", editedImage);

    if (!editIndex) return;

    if (editIndex.isNewImage) {
        setNewImages((prevNewImages) => {
            const updatedNewImages = [...prevNewImages];
            updatedNewImages[editIndex.index] = {
                ...updatedNewImages[editIndex.index],
                src: editedImage, // 🚀 Oppdater bildet på riktig plass
            };
            console.log("🖼️ Oppdatert newImages:", updatedNewImages);
            return updatedNewImages;
        });
    } else {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[editIndex.index] = {
                ...updatedImages[editIndex.index],
                src: editedImage, // 🚀 Oppdater bildet på riktig plass
                hasChanged: true, // ✅ Marker at det er redigert
            };
            console.log("🖼️ Oppdatert images:", updatedImages);
            return updatedImages;
        });
    }

    // 🚀 Nullstill modaldata for å unngå duplisering
    setTimeout(() => {
        setImageToEdit(null);
        setEditIndex(null);
    }, 50);
};

  

  // Lagre alle bilder og kommentarer til API
  const handleSave = async () => {
    try {
      const uploadedImages = [];

      for (const image of newImages) {
        const response = await apiClient.post(`${config.apiUrl}/addReportPhotos/?token=${token}`, {
          bilde: image.src,
          kommentar: image.comment ?? "",  // Sikrer at den aldri er undefined
          oppdrag_id,
        });        
        const oppdrag_bilde_id = response.data.oppdrag_bilde_id;
        uploadedImages.push({ ...image, id: oppdrag_bilde_id });
      }

      setImages([...images, ...uploadedImages]);
      setNewImages([]);
      

      for (const image of images) {
        if (image.hasChanged) {
          await apiClient.put(`${config.apiUrl}/updPhotoComment/?token=${token}`, {
            oppdrag_bilde_id: image.id,
            bilde: image.src,
            kommentar: image.comment,
          });
        }
      }

      notify('success', translations.photosSaved || 'Photos saved successfully.');
      navigate(`/legacy/preview/${behandlingsadrId}/${oppdrag_id}`);
    } catch (error) {
      notify('error', translations.savePhotosError || 'Failed to save photos.');
    }
  };

  const handleDelete = (index, isNewImage) => {
    if (isNewImage) {
      const updatedNewImages = [...newImages];
      updatedNewImages.splice(index, 1);
      setNewImages(updatedNewImages);
    } else {
      const updatedImages = [...images];
      const [deletedImage] = updatedImages.splice(index, 1);
      apiClient.delete(`${config.apiUrl}/delPhotosOppdrag/?token=${token}`, {
        data: { oppdrag_bilde_id: deletedImage.id }
      }).then(() => {
        notify('success', translations.deleteSuccess || 'Image deleted successfully.');
      }).catch((error) => {
        console.error('Error deleting image:', error);
        notify('error', translations.deleteError || 'Failed to delete image.');
      });
      setImages(updatedImages);
    }
  };

  
  const handleCommentChange = (e, index, isNewImage) => {
    const value = e.target.value ?? ""; // Sørger for at tomme felt ikke blir undefined
  
    if (isNewImage) {
      const updatedNewImages = [...newImages];
      updatedNewImages[index].comment = value;
      setNewImages(updatedNewImages);
    } else {
      const updatedImages = [...images];
      updatedImages[index] = {
        ...updatedImages[index],
        comment: value,
        hasChanged: true, // Marker bildet som endret
      };
      setImages(updatedImages);
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };
  const handleRewind = () => {
    navigate(`/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`);
  };

  const handleBack = () => {
    navigate(`/legacy/comment/${behandlingsadrId}/${oppdrag_id}`);
  };
  

    return (
      <MainLayout title={translations.photosTitle || "Photos"}>
        <div className="cr-container">
          <OppdragInfoBanner /> {/* Legg til banneret her */}
          {isLoading ? (
            <div>{translations.loading || 'Loading...'}</div>
          ) : (
            <>
              <h2 className="cr-heading">{translations.addPhotosHeading || 'You can add up to 15 additional photos with comments:'}</h2>
              <div className="cr-box">
                <div className="cr-image-list">
                  {images.map((image, index) => (
                    <div key={index} className="cr-image-item">
                      <img key={`${image.src}-${index}`} src={image.src} alt="uploaded" className="cr-preview-image" />
                      <div className="cr-buttons">
                        <button className="cr-button cr-delete-button" onClick={() => handleDelete(index, false)}>{translations.delete || 'Delete'}</button>
                        <button
                          className="cr-button cr-edit-button"
                          onClick={() => handleEditImage(image, index, false)}
                        >
                          {translations.editImage || 'Edit Image'}
                        </button>
                      </div>
                      <textarea
                        className="cr-textarea-photos"
                        placeholder={translations.commentPlaceholder || 'Comment'}
                        value={image.comment}
                        onChange={(e) => handleCommentChange(e, index, false)}
                      />
                    </div>
                  ))}
                  {newImages.map((image, index) => (
                    <div key={index + images.length} className="cr-image-item">
                      <img src={image.src} alt="new upload" className="cr-preview-image" />
                      <div className="cr-buttons">
                        <button className="cr-button cr-delete-button" onClick={() => handleDelete(index, true)}>{translations.delete || 'Delete'}</button>
                        <button
                          className="cr-button cr-edit-button"
                          onClick={() => handleEditImage(image, index, true)}
                        >
                          {translations.editImage || 'Edit Image'}
                        </button>
                      </div>
                      <textarea
                        className="cr-textarea-photos"
                        placeholder={translations.commentPlaceholder || 'Comment'}
                        value={image.comment}
                        onChange={(e) => handleCommentChange(e, index, true)}
                      />
                    </div>
                  ))}
                </div>
                <div className="cr-image-placeholder" onClick={handleFileClick}>
                  <FaCamera className="cr-camera-icon" />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              <div className="cr-buttons">
                <button className="cr-button cr-rewind-button" onClick={handleRewind}>
                  <FaFastBackward />
                </button>
                <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || 'Back'}</button>
                <button className="cr-button cr-save-button" onClick={handleSave}>{translations.saveButton || 'Save'}</button>
              </div>
            </>
          )}
        </div>
       

        {imageToEdit && (
        <EditImageModal
          show={showEditModal}
          onClose={() => {
            console.log("❌ Lukker EditImageModal, nullstiller state...");
            setShowEditModal(false);
            setTimeout(() => {
              setImageToEdit(null);
              setEditIndex(null);
            }, 50);
          }}
          imageUrl={imageToEdit.src}  // ✅ Sørg for at vi sender riktig bilde
          onSaveImage={handleSaveEditedImage}
        />
      )}

      {/* ✅ Beskjæringsmodal */}
        <CropImageModal
          show={showCropModal}
          imageFile={imageToCrop}
          onClose={() => setShowCropModal(false)}
          onCropComplete={handleCroppedImage}
        />
    
      </MainLayout>
    );
  };

  export default Photos;
