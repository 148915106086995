import React, { useEffect, useState, useContext } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import apiClient from '../api/apiClient';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext';
import { useToast } from '../contexts/ToastContext';
import MainLayout from '../components/MainLayout';
import { FaRoute, FaExclamationCircle, FaMapMarkerAlt } from 'react-icons/fa';

const MapView = () => {
  const [addresses, setAddresses] = useState([]);
  const [failedAddresses, setFailedAddresses] = useState(new Set());
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showFailedAddresses, setShowFailedAddresses] = useState(false);
  const [map, setMap] = useState(null);
  const token = localStorage.getItem('token');
  const { translations } = useContext(LanguageContext);
  const notify = useToast();

  const [center, setCenter] = useState({ lat: 59.911491, lng: 10.757933 });
  
  //  Oppdatert funksjon for gyldige koordinater (parsing av strenger):
  const isValidCoordinate = (value) => {
    const parsed = parseFloat(value);
    return !isNaN(parsed) && parsed !== 0;
  };


  // Fetch addresses on map load
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await apiClient.get(
          `${config.apiUrl}/getMapView/?token=${token}`
        );
        if (response.data.status === 'success') {
          const data = response.data.data;

          // Bruker direkte koordinater fra API-et
          const validAddresses = data.filter(
            (addr) =>
              isValidCoordinate(addr.coord_lat) &&
              isValidCoordinate(addr.coord_lon)
          );

          const invalidAddresses = data.filter(
            (address) => !isValidCoordinate(address.coord_lat) || !isValidCoordinate(address.coord_lon)
          );

      setFailedAddresses(new Set(invalidAddresses));

          setAddresses(validAddresses);

          if (validAddresses.length > 0) {
            setCenter({
              lat: parseFloat(validAddresses[0].coord_lat),
              lng: parseFloat(validAddresses[0].coord_lon),
            });
          }
        } else {
          notify('error', translations['fetchAddressesError'] || 'Failed to fetch addresses');
        }
      } catch (error) {
        notify('error', translations['fetchAddressesError'] || 'Error fetching addresses');
      }
    };

    fetchAddresses();
  }, [token, translations, notify]);

  // Initialize maplibre map
  useEffect(() => {
    if (!map) {
      const initializedMap = new maplibregl.Map({
        container: 'map',
        style: {
          version: 8,
          sources: {
            carto: {
              type: 'raster',
              tiles: ['https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'],
              tileSize: 256,
              attribution: '&copy; <a href="https://carto.com/">CARTO</a>',
            },
          },
          layers: [
            {
              id: 'carto-tiles',
              type: 'raster',
              source: 'carto',
              minzoom: 0,
              maxzoom: 24,
            },
          ],
        },
        center: [center.lng, center.lat],
        zoom: 10,
      });

      initializedMap.addControl(new maplibregl.NavigationControl(), 'top-right');

      initializedMap.on('load', () => {
        setMap(initializedMap);
      });
    }
  }, [map, center]);

  useEffect(() => {
    if (map) {
      addresses.forEach((address) => {
        // Bestem fargen basert på address.pri_color
        const color =
          address.pri_color === 3
            ? 'darkseagreen'
            : address.pri_color === 2
            ? 'gold'
            : 'darksalmon';
  
        // Opprett en standard markør med spesifisert farge
        const marker = new maplibregl.Marker({ color })
          .setLngLat([parseFloat(address.coord_lon), parseFloat(address.coord_lat)])
          .addTo(map);
  
        // Legg til klikkhåndtering for markøren
        marker.getElement().addEventListener('click', (e) => {
          e.stopPropagation(); // Forhindre at kartets klikkhendelse utløses
          setSelectedAddress(address);
        });
      });
    }
  }, [map, addresses]);
  
  const findUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          map.flyTo({ center: [longitude, latitude], zoom: 14 });
        },
        () => {
          notify('error', translations['geolocationError'] || 'Could not fetch your location');
        }
      );
    } else {
      notify('error', translations['geolocationNotSupported'] || 'Geolocation is not supported by your browser');
    }
  };

  const closeInfoWindow = () => {
    setSelectedAddress(null);
  };

  return (
    <MainLayout title={translations['mapView'] || 'Map View'}>
      <div style={{ position: 'relative' }}>
        <div
          id="map"
          style={{ width: '100%', height: '600px' }}
          onClick={closeInfoWindow}
        ></div>

        {selectedAddress && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '15px',
              border: '1px grey',
              borderRadius: '8px',
              zIndex: 1000,
            }}
          >
            <h2>{selectedAddress.navn}</h2>
            <p>{`${selectedAddress.behandlingsadr}, ${selectedAddress.postnr} ${selectedAddress.poststed}`}</p>
            <p>
              {translations['treatments'] || 'Treatments'}:{' '}
              {Array.isArray(selectedAddress.behandling)
                ? selectedAddress.behandling.join(', ')
                : selectedAddress.behandling}
            </p>
            <div style={{ display: 'flex', gap: '10px' }}>
              <button
                onClick={() =>
                  (window.location.href = `/serviceaddress/${selectedAddress.behandlingsadr_id}`)
                }
              >
                {translations['viewServiceCard'] || 'View Service Card'}
              </button>
              <button
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                      selectedAddress.behandlingsadr + ', ' + selectedAddress.poststed
                    )}`
                  )
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FaRoute style={{ marginRight: '5px' }} />
                {translations['directions'] || 'Directions'}
              </button>
            </div>
          </div>
        )}

        {/* Utropstegn-ikon */}
        <div
          onClick={() => {
            setShowFailedAddresses(true);
          }}
          style={{
            position: 'absolute',
            bottom: '120px', // Behold posisjoneringen
            right: '10px',
            color: 'salmon', // Oppdaterer fargen her
            background: '#fff', // Hvit bakgrunn som zoom-knappene
            border: '1px grey', // Lys grå kantlinje for å matche
            borderRadius: '4px', // Kvadratisk med lett avrundede kanter
            width: '36px', // Samme dimensjoner som zoom-knappene
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', // Lett skygge for dybde
            cursor: 'pointer',
            zIndex: 1000,
          }}
        >
          <FaExclamationCircle size={24} />
        </div>

        {showFailedAddresses && (
          <>
            <div
              onClick={() => setShowFailedAddresses(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                zIndex: 999,
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: '60px',
                right: '10px',
                backgroundColor: 'white',
                border: '1px grey',
                borderRadius: '8px',
                padding: '15px',
                width: '300px',
                maxHeight: '400px',
                overflowY: 'auto',
                zIndex: 1000,
              }}
            >
              <h4>{translations['failedAddressesTitle'] || 'Important:'}</h4>
              <p>
                {translations['failedAddressesDescription'] ||
                  'The following addresses could not be recognized and will not be displayed on the map. Please check their spelling to ensure they are entered correctly.'}
              </p>
              <ul style={{ paddingLeft: '20px' }}>
                {Array.from(failedAddresses).length > 0 ? (
                  Array.from(failedAddresses).map((address, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>
                      <strong>{address.navn}</strong>
                      <br />
                      {address.behandlingsadr}, {address.poststed}
                    </li>
                  ))
                ) : (
                  <p>{translations['noFailedAddresses'] || 'No failed addresses found.'}</p>
                )}
              </ul>
            </div>
          </>
        )}

        {/* Geolokasjon-ikon */}
        <div
          onClick={findUserLocation}
          style={{
            position: 'absolute',
            bottom: '60px', // Endret til den nye posisjonen
            right: '10px',
            background: '#fff', // Hvit bakgrunn som zoom-knappene
            border: '1px solid grey', // Lys grå ramme
            borderRadius: '4px', // Lett avrundede hjørner
            width: '36px', // Samme størrelse som zoom-knappene
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', // Lett skygge for dybde
            cursor: 'pointer',
            zIndex: 1000,
          }}
          
        >
          <FaMapMarkerAlt size={24} />
        </div>
      </div>
    </MainLayout>
  );
};

export default MapView;
