import React, { useState, useEffect, useContext } from 'react'; // La til useContext
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import { FaListAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Bruk LanguageContext

const Neighbors = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [nabovarsel, setNabovarsel] = useState(null);
  const [nabovarselKommentar, setNabovarselKommentar] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialNabovarsel, setInitialNabovarsel] = useState(null);
  const [initialNabovarselKommentar, setInitialNabovarselKommentar] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTexts, setModalTexts] = useState([]);
  const [setTextFunction, setSetTextFunction] = useState(() => () => {});
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getNeighborsOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data) {
          setNabovarsel(response.data.data[0].nabovarsel === true ? "1" : "0");
          setNabovarselKommentar(response.data.data[0].nabovarsel_kommentar || '');
          setInitialNabovarsel(response.data.data[0].nabovarsel === true ? "1" : "0");
          setInitialNabovarselKommentar(response.data.data[0].nabovarsel_kommentar || '');
        }

        const customTextResponse = await apiClient.get(`${config.apiUrl}/getCustomtext/4/?token=${token}`);
        if (customTextResponse.data && customTextResponse.data.data) {
          setCustomTexts(customTextResponse.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching neighbors or custom texts:', error);
        notify('error', translations.fetchDataError || 'Failed to fetch data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (nabovarsel !== initialNabovarsel || nabovarselKommentar !== initialNabovarselKommentar) {
      const confirmSave = window.confirm(translations.unsavedChangesConfirm || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/method/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving neighbor notification:', error);
          notify('error', translations.saveNeighborNotificationError || 'Failed to save neighbor notification. Please try again.');
        }
      } else {
        navigate(`/legacy/method/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/method/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (nabovarsel === null) {
      notify('error', translations.selectNeighborNotification || 'Please select whether neighbor notification was given or not.');
      return;
    }
    if (nabovarselKommentar.trim() === '') {
      notify('error', translations.enterNeighborNotificationComment || 'Please provide a comment for the neighbor notification.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updNeighbors/?token=${token}`, {
        oppdrag_id,
        nabovarsel,
        nabovarsel_kommentar: nabovarselKommentar,
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/result/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', translations.neighborNotificationSaved || 'Neighbor notification saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/result/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving neighbor notification:', error);
      notify('error', translations.saveNeighborNotificationError || 'Failed to save neighbor notification. Please try again.');
    }
  };

  const handleCustomTextClick = (texts, setTextFunc) => {
    setModalTexts(texts);
    setSetTextFunction(() => setTextFunc);
    setIsModalOpen(true);
  };

  const handleTextSelect = (text) => {
    setTextFunction((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
  };

  return (
    <MainLayout title={translations.neighborsTitle || "Neighbors"}>
      <div className="cr-container">
        <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <>
            <div className="cr-box">
              <h2 className="cr-heading">{translations.isNeighborNotificationGiven || "Is neighbor notification given?"}</h2>
              <div className="cr-radio-group">
                <label className="cr-radio-label">
                  {translations.yes || "Yes"}
                  <input
                    type="radio"
                    name="nabovarsel"
                    value="1"
                    checked={nabovarsel === "1"}
                    onChange={() => setNabovarsel("1")}
                    className="cr-radio-input"
                  />
                  <span className="cr-radio-custom"></span>
                </label>
                <label className="cr-radio-label">
                  {translations.no || "No"}
                  <input
                    type="radio"
                    name="nabovarsel"
                    value="0"
                    checked={nabovarsel === "0"}
                    onChange={() => setNabovarsel("0")}
                    className="cr-radio-input"
                  />
                  <span className="cr-radio-custom"></span>
                </label>
              </div>
              <div className="cr-textarea-container">
              <textarea
                className={`cr-textarea ${getTextAreaClass(nabovarselKommentar)}`}
                placeholder={translations.commentPlaceholder || "Comment..."}
                value={nabovarselKommentar}
                onChange={(e) => setNabovarselKommentar(e.target.value)}
              />
              <div className="cr-icon-container">
                <FaListAlt className="cr-icon" onClick={() => handleCustomTextClick(customTexts, setNabovarselKommentar)} />
              </div>
              </div>
            </div>

            <div className="cr-buttons">
              <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || "Back"}</button>
              <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.saveButton || "Save"}</button>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectCustomText || "Select Custom Text"}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectTextHeading || "Select a text"}</h2>
        <ul className="cr-modal-list">
          {modalTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">
          {translations.closeButton || "Close"}
        </button>
      </Modal>
    </MainLayout>
  );
};

export default Neighbors;
