import React, { useState, useRef, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import Modal from 'react-modal';
import { FaTrashAlt } from 'react-icons/fa';
import Compressor from 'compressorjs';
import Map from '../components/Map';
import { useToast } from '../contexts/ToastContext';
import '../pages/ServiceAddress.css';
import config from '../config';
import { FaCamera } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext
import './ServiceAddressFloorplan.css';


const ServiceAddressFloorplan = ({ behandlingsadrId, token, floorPlans, fetchServiceCardData, customerAddress }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext
  const [isFloorPlansVisible, setIsFloorPlansVisible] = useState(false);
  const [selectedFloorPlanImage, setSelectedFloorPlanImage] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const [svgIcons, setSvgIcons] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hoveredCheckpoint, setHoveredCheckpoint] = useState(null); // Ny state for hover-effekt
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [floorPlanToDelete, setFloorPlanToDelete] = useState(null);
  const fileInputRef = useRef(null);
  const mapRef = useRef(null);
  const notify = useToast();
  const imageRef = useRef(null); // Ref for image element
  const [isEditing, setIsEditing] = useState(false); // State for editing mode
  const [modifiedCheckpoints, setModifiedCheckpoints] = useState({});
  const [selectedFloorPlanId, setSelectedFloorPlanId] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [checkpointTypes, setCheckpointTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);
  const [selectedCheckpointTypeId, setSelectedCheckpointTypeId] = useState(null);
  const [selectedBaitTypeId, setSelectedBaitTypeId] = useState(null);
  const [selectedCheckpointShape, setSelectedCheckpointShape] = useState('');
  const [selectedCheckpointFillColor, setSelectedCheckpointFillColor] = useState('');
  const [selectedCheckpointLineColor, setSelectedCheckpointLineColor] = useState('');






  useEffect(() => {
    if (selectedFloorPlanImage || checkpoints.length > 0) {
      setIsModalOpen(true);
    }
  }, [selectedFloorPlanImage, checkpoints]);

  // Henter Checkpoint Types og Bait Types når modalen åpnes
  useEffect(() => {
    if (isAddModalOpen) {
      fetchCheckpointTypes();
      fetchBaitTypes();
    }
  }, [isAddModalOpen]); // Trigger når modalen åpnes
  
 //funksjon for nye checkpoints som legges til i app - måtte doble størrelse som en workaround, da ikonene ellers ble for små
  const generateCheckpointSVG = (shape, fillColor, lineColor) => {
    let svgShape = '';

    switch (shape.toLowerCase()) {
        case 'circle':
            svgShape = `<circle cx="24" cy="24" r="20" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>`;
            break;
        case 'square':
            svgShape = `<rect x="8" y="8" width="32" height="32" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>`;
            break;
        case 'triangle':
            svgShape = `<polygon points="24,8 40,40 8,40" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>`;
            break;
        case 'plus':
            svgShape = `
                <rect x="20" y="8" width="8" height="32" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>
                <rect x="8" y="20" width="32" height="8" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>
            `;
            break;
        default:
            svgShape = `<circle cx="24" cy="24" r="20" fill="${fillColor}" stroke="${lineColor}" stroke-width="4"/>`; // Standard fallback
    }

    return `<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">${svgShape}</svg>`;
};


  const fetchCheckpointTypes = async () => {
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCheckpointType?token=${token}`);
      setCheckpointTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching checkpoint types:", error);
    }
  };
  
  const fetchBaitTypes = async () => {
    try {
      const response = await apiClient.get(`${config.apiUrl}/getBaitTypes?token=${token}`);
      const activeBaitTypes = response.data.data.filter(bait => bait.is_active);
      setBaitTypes(activeBaitTypes);
    } catch (error) {
      console.error("Error fetching bait types:", error);
    }
  };
  
  // Funksjon for å vise riktig ikon for valgt checkpoint type
  const renderCheckpointIcon = (checkpointTypeId) => {
    const checkpoint = checkpointTypes.find(type => type.checkpoint_type_id === parseInt(checkpointTypeId, 10));
    if (!checkpoint) return null;
  
    const { shape_type, fill_color, line_color } = checkpoint;
  
    // Returnerer riktig form basert på shape_type
    switch (shape_type.toLowerCase()) {
      case "circle":
        return (
          <svg width="24" height="24" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" fill={fill_color} stroke={line_color} strokeWidth="2" />
          </svg>
        );
      case "square":
        return (
          <svg width="24" height="24" viewBox="0 0 24 24">
            <rect x="4" y="4" width="16" height="16" fill={fill_color} stroke={line_color} strokeWidth="2" />
          </svg>
        );
      case "triangle":
        return (
          <svg width="24" height="24" viewBox="0 0 24 24">
            <polygon points="12,4 4,20 20,20" fill={fill_color} stroke={line_color} strokeWidth="2" />
          </svg>
        );
      case "plus":
        return (
          <svg width="24" height="24" viewBox="0 0 24 24">
            <line x1="12" y1="4" x2="12" y2="20" stroke={line_color} strokeWidth="4" />
            <line x1="4" y1="12" x2="20" y2="12" stroke={line_color} strokeWidth="4" />
          </svg>
        );
      default:
        return null; // Hvis shape_type er ukjent, vises ingenting
    }
  };



  const handleEdit = () => {
    setIsEditing(true);
  };


  const handleSaveCheckpoint = async (event) => {
    event.preventDefault();

    // Hent verdier fra skjemaet
    const newCheckpoint = {
        label: document.getElementById('label').value || '',  
        room: document.getElementById('room').value || '',
        exact_location: document.getElementById('exactDescription').value || '',
        behandlingsadr_id: behandlingsadrId, 
        checkpoint_type_id: selectedCheckpointTypeId,  
        bait_type_id: selectedBaitTypeId || null,  
        barcode: document.getElementById('barcode').value || ''
    };

    try {
        const response = await apiClient.post(`${config.apiUrl}/addCheckpoint?token=${token}`, newCheckpoint);
        
        if (response.status === 200) {
            const newCheckpointId = response.data.data.checkpoint_id;
            
            // Finn midten av bildet
            const posX = 90; // Midten av bildet i %
            const posY = 90;

            // Generer SVG for det nye sjekkpunktet
            const newCheckpointSVG = generateCheckpointSVG(selectedCheckpointShape, selectedCheckpointFillColor, selectedCheckpointLineColor);

            // Oppdater checkpoints state
            setCheckpoints([...checkpoints, { 
                ...newCheckpoint, 
                checkpoint_id: newCheckpointId, 
                point_x: posX, 
                point_y: posY,
                shape_type: selectedCheckpointShape,
                fill_color: selectedCheckpointFillColor,
                line_color: selectedCheckpointLineColor
            }]);

            // Oppdater SVG-icons state
            setSvgIcons({ ...svgIcons, [newCheckpointId]: newCheckpointSVG });

            // Sett Floorplan i edit mode
            setIsEditing(true);

            // Lukk Add Checkpoint-modal
            setIsAddModalOpen(false);
        }
    } catch (error) {
        console.error("Error saving checkpoint:", error);
        notify('error', translations['saveError'] || 'Failed to save checkpoint');
    }
};
  
  const handleDragStart = (e, checkpointId) => {
    e.dataTransfer.setData("checkpointId", checkpointId);
    e.dataTransfer.effectAllowed = "move";
  };

  
  const handleTouchEnd = (e, checkpointId) => {
    const finalPos = modifiedCheckpoints[checkpointId];
    if (finalPos) {
      setCheckpoints(checkpoints.map(cp => {
        if (cp.checkpoint_id === checkpointId) {
          return {...cp, point_x: finalPos.x, point_y: finalPos.y};
        }
        return cp;
      }));
      setModifiedCheckpoints({});
    }
  };

  const handleTouchMove = (e, checkpointId) => {
    if (!isEditing) return;

    // Få tilgang til touch-eventens koordinater
    const touch = e.touches[0]; // Få den første touchen
    const imageBounds = imageRef.current.getBoundingClientRect();

    // Juster posisjonen litt basert på observasjonene dine
    const offsetX = 20; // Juster disse verdiene basert på testing
    const offsetY = 18;

    const x = ((touch.clientX - imageBounds.left - offsetX) / imageBounds.width) * 100;
    const y = ((touch.clientY - imageBounds.top - offsetY) / imageBounds.height) * 100;

    setModifiedCheckpoints({
        ...modifiedCheckpoints,
        [checkpointId]: { x, y }
    });
};
  
  const handleDragOver = (e) => {
    e.preventDefault(); // Tillater dropping
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    const checkpointId = e.dataTransfer.getData("checkpointId");
    const imageBounds = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - imageBounds.left) / imageBounds.width) * 100;
    const y = ((e.clientY - imageBounds.top) / imageBounds.height) * 100;
  
    const updatedCheckpoints = checkpoints.map(cp => cp.checkpoint_id === checkpointId ? 
      { ...cp, point_x: x, point_y: y } : cp);
    
    setCheckpoints(updatedCheckpoints); // Oppdaterer posisjonen i checkpoints
    setModifiedCheckpoints({...modifiedCheckpoints, [checkpointId]: {x, y}});
    console.log('Dropped at:', x, y);
  };  

  const handleSave = async () => {
    // Samle oppdateringer for flyttede sjekkpunkter
    const updatePromises = Object.keys(modifiedCheckpoints).map(checkpointId => {
        const { x, y } = modifiedCheckpoints[checkpointId];
        const imageElement = imageRef.current;
        const actualX = x * imageElement.naturalWidth / 100;
        const actualY = y * imageElement.naturalHeight / 100;

        return apiClient.put(`${config.apiUrl}/updCheckpointMapping?token=${token}`, {
            checkpoint_id: checkpointId,
            floorplan_id: selectedFloorPlanId,
            point_x: actualX,
            point_y: actualY
        });
    });

    // Håndter lagring av NYE sjekkpunkter som har blitt flyttet
    const newCheckpointPromises = checkpoints
        .filter(cp => cp.isNew) // Finn sjekkpunkter som er nye
        .map(newCp => {
            const { checkpoint_id, point_x, point_y } = newCp;
            const imageElement = imageRef.current;
            const actualX = (point_x / 100) * imageElement.naturalWidth;
            const actualY = (point_y / 100) * imageElement.naturalHeight;

            return apiClient.put(`${config.apiUrl}/updCheckpointMapping?token=${token}`, {
                checkpoint_id,
                floorplan_id: selectedFloorPlanId,
                point_x: actualX,
                point_y: actualY
            });
        });

    try {
        // Kjører alle oppdateringer for både eksisterende og nye sjekkpunkter
        await Promise.all([...updatePromises, ...newCheckpointPromises]);

        // Oppdater floorplan for å vise lagrede posisjoner
        handleViewFloorPlanImage(selectedFloorPlanId);

        // Nullstill endringer
        setModifiedCheckpoints({});
        setIsEditing(false);
        notify('success', translations['changesSaved'] || 'Changes saved successfully');
    } catch (error) {
        console.error('Error updating checkpoint mappings:', error);
        notify('error', translations['saveError'] || 'Failed to save changes');
    }
};

// 🛠️ Oppdater handleViewFloorPlanImage for å laste inn SVG-er for nye sjekkpunkter også!
const handleViewFloorPlanImage = async (floorplan_id) => {
    try {
        const imageResponse = await apiClient.get(`${config.apiUrl}/getFloorplanImage/${floorplan_id}?token=${token}`);
        setSelectedFloorPlanImage(imageResponse.data.data.image);
        setSelectedFloorPlanId(floorplan_id);

        const checkpointsResponse = await apiClient.get(`${config.apiUrl}/getCheckpointByFloorplan/${floorplan_id}?token=${token}`);
        const checkpointData = checkpointsResponse.data.data;
        setCheckpoints(checkpointData);

        // Fetch SVGs for each checkpoint and store them in state
        const svgFetchPromises = checkpointData.map(async (checkpoint) => {
            const iconSrc = `/assets/checkpoint-categories/${checkpoint.shape_type.toLowerCase()}.svg`;
            const response = await fetch(iconSrc);
            let svgText = await response.text();

            // Modifiserer SVG-en for å sette riktig fill og stroke
            svgText = svgText.replace(/<svg/, `<svg fill="${checkpoint.fill_color}" stroke="${checkpoint.line_color}"`);
            svgText = svgText.replace(/fill=".*?"/g, `fill="${checkpoint.fill_color}"`);
            svgText = svgText.replace(/stroke=".*?"/g, `stroke="${checkpoint.line_color}"`);

            return { checkpointId: checkpoint.checkpoint_id, svgContent: svgText };
        });

        const svgResults = await Promise.all(svgFetchPromises);
        const svgIconsMap = {};
        svgResults.forEach((item) => {
            svgIconsMap[item.checkpointId] = item.svgContent;
        });
        setSvgIcons(svgIconsMap);

    } catch (error) {
        console.error('Error fetching floor plan image or checkpoints:', error);
        notify('error', translations['fetchErrorFP'] || 'Failed to fetch floor plan image or checkpoints.');
    }
};


//Lukker floorplan modal
  const closeModal = () => {
    if (Object.keys(modifiedCheckpoints).length > 0 && isEditing) {
      // Bekreft om brukeren ønsker å forkaste endringene
      if (window.confirm("Du har ulagrede endringer. Er du sikker på at du vil forkaste disse endringene?")) {
        resetFloorplan();
      } else {
        // Brukeren velger å ikke lukke modalen
        return;
      }
    } else {
      resetFloorplan();
    }
  };
  
  //resetter floorplan modal før lukking
  const resetFloorplan = () => {
    setIsModalOpen(false);
    setSelectedFloorPlanImage(null);  // Reset state when modal closes
    setCheckpoints([]);               // Reset state when modal closes
    setSvgIcons({});                  // Reset SVG icons when modal closes
    setHoveredCheckpoint(null);       // Reset hover state
    setIsEditing(false);
    setModifiedCheckpoints({});       // Tøm de modifiserte sjekkpunktene
  };
  const openDeleteModal = (floorplan) => {
    setFloorPlanToDelete(floorplan);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setFloorPlanToDelete(null);
    setDeleteModalVisible(false);
  };

  const handleDeleteFloorPlan = async () => {
    if (!floorPlanToDelete) return;

    try {
      const response = await apiClient.delete(
        `${config.apiUrl}/delFloorplan/${floorPlanToDelete.floorplan_id}?token=${token}`
      );

      if (response.status === 200) {
        fetchServiceCardData(behandlingsadrId); // Oppdater listen etter sletting
        notify('success', translations['deleteSuccessFP'] || 'Floor plan deleted successfully.');
        closeDeleteModal();
      }
    } catch (error) {
      console.error('Error deleting floor plan:', error);
      notify('error', translations['deleteErrorFP'] || 'Failed to delete floor plan.');
    }
  };

  const handleMouseOver = (checkpoint) => {
    setHoveredCheckpoint(checkpoint);
  };

  const handleMouseOut = () => {
    setHoveredCheckpoint(null);
  };



  const renderCheckpoints = () => {
    const imageElement = imageRef.current;
    if (!imageElement) return null;

    const imageWidth = imageElement.naturalWidth;
    const imageHeight = imageElement.naturalHeight;

    return checkpoints.map((checkpoint) => {
        const { point_x, point_y, checkpoint_id, label } = checkpoint;

        // Hvis sjekkpunktet er nylig lagt til, plasser det midt i bildet
        const modifiedPos = modifiedCheckpoints[checkpoint_id];
        const posX = modifiedPos ? modifiedPos.x : (point_x !== undefined ? (point_x / imageWidth) * 100 : 50);
        const posY = modifiedPos ? modifiedPos.y : (point_y !== undefined ? (point_y / imageHeight) * 100 : 50);

        const iconStyle = {
            position: 'absolute',
            left: `${posX}%`,
            top: `${posY}%`,
            transform: 'translate(-50%, -50%)',
            cursor: isEditing ? 'move' : 'pointer',
        };

        return (
            <div
                key={checkpoint_id}
                style={iconStyle}
                draggable={isEditing}
                onDragStart={(e) => handleDragStart(e, checkpoint_id)}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e)}
                onMouseOver={() => !isEditing && handleMouseOver(checkpoint)}
                onMouseOut={() => !isEditing && handleMouseOut()}
                onTouchStart={() => !isEditing && handleMouseOver(checkpoint)}  
                onTouchMove={(e) => handleTouchMove(e, checkpoint_id)}
                onTouchEnd={() => handleTouchEnd()}
            > 
                <div dangerouslySetInnerHTML={{ __html: svgIcons[checkpoint_id] || '' }} />
                <div style={{ textAlign: 'center', fontSize: '10px', color: '#000', fontWeight: 'bold' }}>{label}</div>
                {hoveredCheckpoint === checkpoint && !isEditing && (
                    <div style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translate(-50%, -10px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: '#fff',
                        padding: '10px',
                        borderRadius: '5px',
                        zIndex: 1001,
                        whiteSpace: 'nowrap',
                        pointerEvents: 'none',
                    }}>
                        <p><strong>{translations['label'] || 'Label'}:</strong> {checkpoint.label}</p>
                        <p><strong>{translations['barcode'] || 'Barcode'}:</strong> {checkpoint.barcode}</p>
                        <p><strong>{translations['sequence'] || 'Sequence'}:</strong> {checkpoint.sequence}</p>
                        <p><strong>{translations['type'] || 'Type'}:</strong> {checkpoint.checkpoint_type}</p>
                        <p><strong>{translations['baitTrapType'] || 'Bait or Trap Type'}:</strong> {checkpoint.bait_type}</p>
                        <p><strong>{translations['checkpointCategory'] || 'Checkpoint Category'}:</strong> {checkpoint.checkpoint_type}</p>
                        <p><strong>{translations['room'] || 'Room'}:</strong> {checkpoint.room}</p>
                        <p><strong>{translations['consumption'] || 'Consumption'}:</strong> {checkpoint.consumption}</p>
                    </div>
                )}
            </div>
        );
    });
};


  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setPreviewImage(null);
    setDocumentName('');
    setDescription('');
  };

  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
            setSelectedFile(compressedResult);
          };
          reader.readAsDataURL(compressedResult);
        },
      });
    }
  };

  const handleUpload = async () => {
    if (!documentName) {
      notify('error', translations['documentNameRequired'] || 'Document name is required.');
      return;
    }

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;
        const data = {
          behandlingsadr_id: behandlingsadrId,
          floorplan_name: documentName,
          description: description,
          image: base64data
        };

        try {
          await apiClient.post(`${config.apiUrl}/addFloorPlan?token=${token}`, data);
          fetchServiceCardData(behandlingsadrId);
          closeUploadModal();
          notify('success', translations['uploadSuccess'] || 'Floor plan uploaded successfully.');
        } catch (error) {
          console.error('Error uploading floor plan:', error);
          notify('error', translations['uploadError'] || 'Failed to upload floor plan.');
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleTakeScreenshot = async () => {
    const map = mapRef.current.getMap();
    if (!map) {
      notify('error', translations['mapError'] || 'Map element not found!');
      return;
    }

    try {
      map.once('idle', () => {
        const canvas = map.getCanvas();
        const base64image = canvas.toDataURL('image/png');

        const data = {
          behandlingsadr_id: behandlingsadrId,
          floorplan_name: documentName,
          description: description,
          image: base64image
        };

        apiClient.post(`${config.apiUrl}/addFloorPlan?token=${token}`, data)
          .then(() => {
            fetchServiceCardData(behandlingsadrId);
            closeMapModal();
            notify('success', translations['screenshotSuccess'] || 'Screenshot taken and uploaded successfully.');
          })
          .catch((error) => {
            console.error('Error taking screenshot and uploading:', error);
            notify('error', translations['screenshotError'] || 'Failed to upload screenshot.');
          });
      });

      map.resize();
    } catch (error) {
      console.error('Error taking screenshot and uploading:', error);
      notify('error', translations['screenshotError'] || 'Failed to take screenshot.');
    }
  };

  return (
    <div className="expandable-section">
      <button className="expand-button" onClick={() => setIsFloorPlansVisible(!isFloorPlansVisible)}>
        {translations['floorPlans'] || 'Floor Plans'}
      </button>
      {isFloorPlansVisible && (
        <div className="expandable-content">
          <div className="button-group">
            <button className="button upload-button" onClick={openMapModal}>
              {translations['captureMap'] || 'Capture from Map'}
            </button>
            <button className="button upload-button" onClick={openUploadModal}>
              {translations['uploadCamera'] || 'Upload from Camera'}
            </button>
          </div>
          {floorPlans.length > 0 ? (
            floorPlans.map((floorPlan) => (
              <div key={floorPlan.floorplan_id} className="floorplan-card">
                <p><strong>{floorPlan.floorplan_name}</strong></p>
                <p>{floorPlan.description}</p>
                <p>
                  <button 
                    onClick={() => handleViewFloorPlanImage(floorPlan.floorplan_id)} 
                    style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                    {translations['viewImage'] || 'View Image'}
                  </button>
                </p>
                <button onClick={() => openDeleteModal(floorPlan)}
                className="task-footer-button delete-button"
                style={{ marginLeft: 'auto' }}
                >                
                <FaTrashAlt /> {translations['delete'] || 'Delete'}
                </button>
              </div>
            ))
          ) : (
            <p>{translations['noFloorPlans'] || 'No Floor Plans'}</p>
          )}
        </div>
      )}

      <Modal
        isOpen={deleteModalVisible}
        onRequestClose={closeDeleteModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '500px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    }
                }}
      >
        <h2>{translations['confirmDeleteFP'] || 'Confirm Delete'}</h2>
        <p>{translations['deleteConfirmationFP'] || 'Are you sure you want to delete this floor plan?'}</p>
        <button onClick={closeDeleteModal} className="task-footer-button resume-button">
                    {translations['cancel'] || 'Cancel'}
        </button>
        <button onClick={handleDeleteFloorPlan} className="task-footer-button delete-button">
          <FaTrashAlt /> {translations['confirmDeleteFP'] || 'Confirm Delete'}
        </button>
      </Modal>          
    
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="floorplan-modal"
        overlayClassName="floorplan-overlay"
    >
        <div style={{ position: 'relative' }}>
            {selectedFloorPlanImage && (
                <img
                    ref={imageRef}
                    src={selectedFloorPlanImage}
                    alt="Floor Plan"
                    className="modal-image"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                />
            )}
            {renderCheckpoints()}
        </div>

        <div className="modal-buttons">
            <button 
                className="add-cp-button" 
                onClick={() => { 
                    setIsAddModalOpen(true); 
                    setIsEditing(true);  // Sett Floorplan-modal i Edit Mode automatisk 
                }}>
                {translations['newCheckpoint'] || 'New Checkpoint'}
            </button> 

            <button className="edit-cp-button" onClick={handleEdit} disabled={isEditing}>
                {translations['moveCheckpoint'] || 'Move Checkpoint'}
            </button>

            <button className="save-cp-button" onClick={handleSave} disabled={!isEditing}>
                {translations['saveChanges'] || 'Save Changes'}
            </button>
        </div>

        <button onClick={closeModal} className="close-modal-button" style={{ display: 'block', margin: '20px auto' }}>
            {translations['close'] || 'Close'}
        </button>
      </Modal>



      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        className="add-checkpoint-modal"
        overlayClassName="add-checkpoint-overlay"
      >
        <form className="add-checkpoint-form" onSubmit={handleSaveCheckpoint}>
          <h2>{translations['addCheckpoint'] || 'Add Checkpoint'}</h2>

          {/* Label */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="label">{translations['label'] || 'Label'}</label>
            <input 
              type="text" 
              id="label"
              className="add-checkpoint-input mandatory"
            />
          </div>

          {/* Checkpoint Type Dropdown */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="checkpointType">{translations['checkpointType'] || 'Checkpoint Type'}</label>
            <select 
              id="checkpointType" 
              className="add-checkpoint-input mandatory" 
              value={selectedCheckpointTypeId} 
              onChange={(e) => {
                const selectedType = checkpointTypes.find(type => type.checkpoint_type_id === parseInt(e.target.value));
                setSelectedCheckpointTypeId(e.target.value);
                setSelectedCheckpointShape(selectedType?.shape_type || '');
                setSelectedCheckpointFillColor(selectedType?.fill_color || '');
                setSelectedCheckpointLineColor(selectedType?.line_color || '');
              }}
            >
              <option value="">{translations['choose'] || 'Choose'}</option>
              {checkpointTypes.map((type) => (
                <option key={type.checkpoint_type_id} value={type.checkpoint_type_id}>
                  {type.checkpoint_type}
                </option>
              ))}
            </select>
          </div>

          {/* Ikonvisning for valgt Checkpoint */}
          {selectedCheckpointTypeId && selectedCheckpointShape && (
            <div className="checkpoint-icon-preview">
              {renderCheckpointIcon(selectedCheckpointShape, selectedCheckpointFillColor, selectedCheckpointLineColor)}
            </div>
          )}

          {/* Bait Type Dropdown */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="baitType">{translations['baitType'] || 'Bait Type'}</label>
            <select 
              id="baitType" 
              className="add-checkpoint-input mandatory" 
              value={selectedBaitTypeId} 
              onChange={(e) => setSelectedBaitTypeId(e.target.value)}
            >
              <option value="">{translations['choose'] || 'Choose'}</option>
              {baitTypes.map((bait) => (
                <option key={bait.bait_type_id} value={bait.bait_type_id}>
                  {bait.bait_type}
                </option>
              ))}
            </select>
          </div>

          {/* Room */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="room">{translations['room'] || 'Room'}</label>
            <input type="text" id="room" className="add-checkpoint-input" />
          </div>

          {/* Exact Description */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="exactDescription">{translations['exactDescription'] || 'Exact Description'}</label>
            <input type="text" id="exactDescription" className="add-checkpoint-input" />
          </div>

          {/* Barcode */}
          <div className="add-checkpoint-form-group">
            <label htmlFor="barcode">{translations['barcode'] || 'Barcode'}</label>
            <input type="text" id="barcode" className="add-checkpoint-input" />
          </div>

          {/* Buttons */}
          <div className="add-checkpoint-button-group">
            <button 
              type="button" 
              className="add-checkpoint-cancel-button" 
              onClick={() => setIsAddModalOpen(false)}
            >
              {translations['cancel'] || 'Cancel'}
            </button>
            <button 
              type="submit" 
              className="add-checkpoint-save-button" 
              disabled={
                !document.getElementById('label')?.value || 
                !document.getElementById('checkpointType')?.value || 
                !document.getElementById('baitType')?.value
              }
            >
              {translations['save'] || 'Save'}
            </button>
          </div>
        </form>
      </Modal>




      <Modal
        isOpen={isUploadModalOpen}
        onRequestClose={closeUploadModal}
        className="upload-modal"
        overlayClassName="upload-overlay"
      >
        <div className="upload-modal-content">
          <div className="upload-image-preview" onClick={handleFileClick}>
            {previewImage ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : (
              <FaCamera className="camera-icon" />
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
          <div className="upload-form">
            <h2>{translations['uploadFloorPlan'] || 'Upload Floor Plan'}</h2>
            <input
              type="text"
              placeholder={translations['documentName'] || 'Document Name'}
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              className={`input-field ${documentName ? 'input-valid' : 'input-required'}`}
            />
            <input
              type="text"
              placeholder={translations['description'] || 'Description (Optional)'}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="input-field"
            />
            <button onClick={handleUpload} className="button upload-button">
              {translations['upload'] || 'Upload'}
            </button>
            <button onClick={closeUploadModal} className="button cancel-button">
              {translations['cancel'] || 'Cancel'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isMapModalOpen}
        onRequestClose={closeMapModal}
        contentLabel="Map Modal"
        style={{
          content: {
            top: '100px',
            left: '50%',
            right: 'auto',
            bottom: 'auto', // 🎯 Endret fra '100px' til 'auto' for dynamisk høyde
            marginRight: '-50%',
            transform: 'translate(-50%, 0)',
            width: '80%',
            height: 'auto', // 🎯 Lar høyden tilpasse seg innholdet
            maxHeight: '68vh', // 🎯 Hindrer at modalen blir for høy
            overflowY: 'auto', // 🎯 Legger til scrolling om nødvendig
          },
        }}
      >
        <button onClick={closeMapModal} className="close-modal-button">
          {translations['close'] || 'Close'}
        </button>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder={translations['documentName'] || 'Document Name'}
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            className={`input-field ${documentName ? 'input-valid' : 'input-required'}`}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <input
            type="text"
            placeholder={translations['description'] || 'Description (Optional)'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input-field"
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <button onClick={handleTakeScreenshot} className="button upload-button">
            {translations['takeScreenshot'] || 'Take Screenshot'}
          </button>
        </div>
        <div className="map-container">
          <Map ref={mapRef} initialAddress={customerAddress} />
        </div>

      </Modal>
    </div>
  );
};

export default ServiceAddressFloorplan;
