import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Preview.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient';
import config from '../../config';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';
import { useToast } from '../../contexts/ToastContext';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress';
import { LanguageContext } from '../../contexts/LanguageContext';

const Preview = () => {
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [reportData, setReportData] = useState({});
  const [treatments, setTreatments] = useState([]);
  const [products, setProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [missingFieldsModalIsOpen, setMissingFieldsModalIsOpen] = useState(false);
  const [missingFields, setMissingFields] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const customerSignatureRef = useRef(null);
  const technicianSignatureRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast();
  const [companyInfo, setCompanyInfo] = useState({});
  const [bilder, setBilder] = useState([]);
  const [images, setImages] = useState([]);
  const [pages, setPages] = useState([]);
  const hiddenContainerRef = useRef(null);
  const [elementHeights, setElementHeights] = useState([]);
  const [imageHeights, setImageHeights] = useState({});

  // Måler høyden på skjulte elementer (hvis du har en skjult container for måling)
  useLayoutEffect(() => {
    if (hiddenContainerRef.current) {
      const elements = hiddenContainerRef.current.children;
      let measuredHeights = [];
      for (let el of elements) {
        measuredHeights.push(el.getBoundingClientRect().height);
      }
      setElementHeights(measuredHeights);
    }
  }, [reportData, images]);

  // Hent data fra API-er
  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getCompanyInfo/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setCompanyInfo({
            address: response.data.data.address,
            zip_code: response.data.data.zip_code,
            postal: response.data.data.postal,
            mail: response.data.data.mail,
            organization_no: response.data.data.organization_no,
            logo_1: response.data.data.logo_1.startsWith('data:image')
              ? response.data.data.logo_1
              : `data:image/png;base64,${response.data.data.logo_1}`,
            telephone: response.data.data.telephone
          });
        }
      })
      .catch(error => console.error('Error fetching company info:', error));

    apiClient.get(`${config.apiUrl}/getOppdragInfo/${oppdrag_id}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setReportData(prevData => ({
            ...prevData,
            oppdrag_id: response.data.data.oppdrag_id,
            behandlingsadr_id: response.data.data.behandlingsadr_id,
            avtale_id: response.data.data.avtale_id,
            tekniker_navn: response.data.data.tekniker_navn
          }));
        }
      })
      .catch(error => console.error('Error fetching report info:', error));

    apiClient.get(`${config.apiUrl}/getOppdragBilder/${oppdrag_id}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setImages(response.data.data);
          setBilder(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching images:', error));

    apiClient.get(`${config.apiUrl}/getReportPreview/${oppdrag_id}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setReportData(response.data.data.oppdrag_details[0]);
          setTreatments(response.data.data.treatments);
          setProducts(response.data.data.products);
        }
      })
      .catch(error => console.error('Error fetching report preview:', error));
  }, [oppdrag_id, token]);

  // Komponent for dynamisk måling av bilder
  const ImageWithHeight = ({ src, index }) => {
    const imgRef = useRef();

    useEffect(() => {
      const handleLoad = () => {
        const height = imgRef.current.clientHeight;
        setImageHeights(prev => ({ ...prev, [index]: height }));
      };

      const imgEl = imgRef.current;
      if (imgEl && imgEl.complete) {
        handleLoad();
      } else {
        imgEl.addEventListener('load', handleLoad);
        return () => imgEl.removeEventListener('load', handleLoad);
      }
    }, [src, index]);

    return <img ref={imgRef} src={src} alt={`Bilde ${index + 1}`} className="preview-report-image" />;
  };

  // Generer sider når data er lastet og høyder er målt
  useEffect(() => {
    generatePages();
  }, [reportData, images, elementHeights, imageHeights]);

  // --- ENDELSE FOR SIDETALLENE --- 
  // Vi definerer en funksjon som lager header med dynamiske sidetall ("Side x av y")
  const createHeader = (pageNum, totalPages) => (
    <div className="preview-header" key={`header-${pageNum}`}>
      <div className="preview-logo">
        {companyInfo.logo_1 && <img src={companyInfo.logo_1} alt="Company Logo" className="company-logo" />}
      </div>
      <div className="preview-company-info">
        <p>{companyInfo.address}, {companyInfo.zip_code} {companyInfo.postal}</p>
        <p>{companyInfo.telephone}</p>
        <p>{companyInfo.mail}</p>
        <p>Org. nr.: {companyInfo.organization_no}</p>
      </div>
      <div className="preview-header-divider"></div>
      <div className="preview-oppdrag-info">
        <p><strong>Side:</strong> {pageNum} av {totalPages}</p>
        <p><strong>Oppdragsnummer:</strong> {reportData.oppdrag_id}</p>
        <p><strong>Avtalenummer:</strong> {reportData.avtale_id}</p>
        <p><strong>Tekniker:</strong> {reportData.tekniker_navn}</p>
        <p><strong>Rapport opprettet:</strong> {reportData.dato_ferdig || 'Ikke tilgjengelig'}</p>
      </div>
    </div>
  );
  // --- SLUTT PÅ ENDELSE FOR SIDETALLENE ---

  // Fullstendig funksjon for dynamisk sidegenerering
  const generatePages = () => {
    let pagesArr = [];
    let currentPageContent = [];
    let currentHeight = 0;
    const maxHeight = 1400; // Juster denne verdien slik at den tilsvarer én A4-side

    // Funksjon som oppretter en ny side med .preview-cr-box
    const addPage = () => {
      // Lagre den nåværende siden uten header (header skal legges til i final mapping)
      pagesArr.push(currentPageContent);
      currentPageContent = [];
      currentHeight = 0;
      // Legg til header på toppen av neste side som en plassholder
      currentPageContent.push("HEADER_PLACEHOLDER");
      currentHeight += elementHeights[0] || 300;
    };

    // Legg til header på første side (bruk en plassholder – vi oppdaterer senere)
    currentPageContent.push("HEADER_PLACEHOLDER");
    currentHeight += elementHeights[0] || 300;

    // Main content
    const mainContent = (
      <div className="preview-main_wrapper" key="mainContent">
        <div className="preview-trans-head">
          <h1 className="text-center preview-green-text">Servicerapport</h1>
        </div>
        <div className="preview-rw">
          <div className="preview-cl preview-cl70">
            <table className="preview-cntable">
              <tbody>
                <tr>
                  <th className="preview-cl40">Kunde nr.:</th>
                  <td>{reportData.kundenr}</td>
                </tr>
                <tr>
                  <th>Kunde:</th>
                  <td>{reportData.navn}</td>
                </tr>
                <tr>
                  <th>Adresse:</th>
                  <td>{reportData.adresse}<br />{reportData.postnr} {reportData.poststed}</td>
                </tr>
                <tr>
                  <th>Telefon:</th>
                  <td>{reportData.telefon}</td>
                </tr>
                <tr>
                  <th>E-post:</th>
                  <td>{reportData.epost}</td>
                </tr>
                <tr>
                  <th>Kontakt:</th>
                  <td>{reportData.kontakt}</td>
                </tr>
                <tr>
                  <th>Behandlingsadr.:</th>
                  <td>{reportData.behandlingsadr}<br />{reportData.postnr_beh} {reportData.poststed_beh}</td>
                </tr>
                <tr>
                  <th>Type besøk:</th>
                  <td>{reportData.typebesok}</td>
                </tr>
                <tr>
                  <th className="preview-cl30">Kontroll/behandling:</th>
                  <td>
                    {treatments.map(treatment => (
                      <span key={treatment.behandling_id}>{treatment.behandling}, </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="preview-cl preview-cl30">
            <img src={reportData.oppdrag_bilde} alt="Behandlingsadresse" />
          </div>
        </div>
      </div>
    );
    if (currentHeight + (elementHeights[1] || 400) > maxHeight) {
      addPage();
    }
    currentPageContent.push(mainContent);
    currentHeight += elementHeights[1] || 400;

    // Content section: Hva er utført, metode osv.
    const contentSection = (
      <div className="preview-main_wrapper" key="contentSection">
        <table className="preview-cntable">
          <tbody>
            <tr>
              <th className="preview-cl30">Hva er utført?</th>
              <td className="preview-cl70 pre-wrap">{reportData.utfort}</td>
            </tr>
            <tr>
              <th className="preview-cl30">Har det vært skadedyraktivitet?</th>
              <td className="preview-cl70">{reportData.s_aktivitet ? 'Ja' : 'Nei'}</td>
            </tr>
            <tr>
              <th className="preview-cl30">Valg av metode:</th>
                <td className="preview-cl70 pre-wrap">{reportData.metode_bekjempelse}</td>
            </tr>
            <tr>
              <th className="preview-cl30">Metode begrunnelse:</th>
              <td className="preview-cl70 pre-wrap">{reportData.metode_begrunnelse}</td>
            </tr>
            <tr>
              <th className="preview-cl30">Er nabovarsel gitt?</th>
              <td className="preview-cl70 pre-wrap">{reportData.nabovarsel_kommentar}</td>
            </tr>
            <tr>
              <th className="preview-cl30">Kommentar til nabovarsel:</th>
              <td className="preview-cl70">{reportData.nabovarsel_kommentar}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    if (currentHeight + (elementHeights[2] || 300) > maxHeight) {
      addPage();
    }
    currentPageContent.push(contentSection);
    currentHeight += elementHeights[2] || 300;

    // Products section
    const productsSection = (
      <div className="preview-main_wrapper" key="productsSection">
        <table className="preview-cntable">
          <thead>
            <tr>
              <th>Anvendt produkt</th>
              <th>Aktivt stoff</th>
              <th>Konsentrasjon</th>
              <th>Måleenhet</th>
              <th>Ant.</th>
              <th>Kommentar</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.produkt_id}>
                <td>{product.produkt}</td>
                <td>{product.aktivtstoff}</td>
                <td>{product.konsentrasjon}</td>
                <td>{product.maleenhet}</td>
                <td>{product.antall}</td>
                <td>{product.mengdekommentar}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
    if (currentHeight + (elementHeights[3] || 300) > maxHeight) {
      addPage();
    }
    currentPageContent.push(productsSection);
    currentHeight += elementHeights[3] || 300;

    // Result section
    const resultSection = (
      <div className="preview-main_wrapper" key="resultSection">
        <table className="preview-cntable">
          <tbody>
            <tr>
              <th>Resultat av behandling</th>
            </tr>
            <tr>
              <td className="preview-cl70 pre-wrap">{reportData.behandling_resultat}</td>
            </tr>
            <tr>
              <th>Risikovurdering av tiltak</th>
            </tr>
            <tr>
              <td className="preview-cl70 pre-wrap">{reportData.risikovurdering_tiltak}</td>
            </tr>
            <tr>
              <th>Kommentar til kontroll/behandling</th>
            </tr>
            <tr>
              <td className="preview-cl70 pre-wrap">{reportData.behandling_kommentar}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    if (currentHeight + (elementHeights[4] || 300) > maxHeight) {
      addPage();
    }
    currentPageContent.push(resultSection);
    currentHeight += elementHeights[4] || 300;

    // Legg til hvert bilde individuelt
    bilder.forEach((bilde, index) => {
      const imageElement = (
        <div key={`image-${index}`} className="preview-image-section">
          <ImageWithHeight src={bilde.bilde} index={index} />
          <div className="preview-image-text">
            <p>{bilde.kommentar}</p>
          </div>
        </div>
      );
      const imgHeight = imageHeights[index] || 300;
      if (currentHeight + imgHeight > maxHeight) {
        addPage();
      }
      currentPageContent.push(imageElement);
      currentHeight += imgHeight;
    });

    // Legg til siste siden hvis det er gjenværende innhold
    if (currentPageContent.length > 0) {
      addPage();
    }

    // Her oppdaterer vi hver side med korrekt header: nå vet vi totalt antall sider
    const totalPages = pagesArr.length;
    const finalPages = pagesArr.map((pageContent, idx) => {
      // Erstatt HEADER_PLACEHOLDER med riktig header
      const updatedContent = pageContent.map(item =>
        item === "HEADER_PLACEHOLDER" ? createHeader(idx + 1, totalPages) : item
      );
      return (
        <div
          key={idx}
          className="preview-cr-box"
          style={{ height: '1200px', overflow: 'hidden', pageBreakAfter: 'always' }}
        >
          <div className="preview-page">
            {updatedContent.map((content, i) => (
              <React.Fragment key={i}>{content}</React.Fragment>
            ))}
          </div>
        </div>
      );
    });

    setPages(finalPages);
  };

  const handleBack = () => {
    navigate(`/legacy/photos/${behandlingsadrId}/${oppdrag_id}`);
  };

  const handleOpenModal = async () => {
    try {
      const response = await apiClient.get(`${config.apiUrl}/getOppdragStatus/${oppdrag_id}/?token=${token}`);
      const oppdragStatus = response.data.data[0];
      if (oppdragStatus.complete) {
        setModalIsOpen(true);
      } else {
        setMissingFields(oppdragStatus.missing);
        setMissingFieldsModalIsOpen(true);
      }
    } catch (error) {
      console.error('Error checking report status:', error);
      notify('error', translations.errorCheckingReportStatus || 'Failed to check report status.');
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const closeMissingFieldsModal = () => {
    setMissingFieldsModalIsOpen(false);
  };

  const handleResetSignature = (signatureRef) => {
    signatureRef.current.clear();
  };

  const handleSaveAndSubmit = async () => {
    if (technicianSignatureRef.current.isEmpty()) {
      notify('error', 'Technician signature is required.');
      return;
    }
    const customerSignature = customerSignatureRef.current.isEmpty() ? '' : customerSignatureRef.current.toDataURL();
    const technicianSignature = technicianSignatureRef.current.toDataURL();
    setIsSaving(true);
    try {
      await apiClient.post(`${config.apiUrl}/updSignatures`, {
        oppdrag_id,
        signatur_kunde: customerSignature,
        signatur_teknikker: technicianSignature,
        token
      });
      await apiClient.post(`${config.apiUrl}/completeReport`, {
        dato_ferdig: new Date().toISOString().split('T')[0],
        status_id: 2,
        oppdrag_id,
        token
      });
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, null, navigate);
      if (success) {
        notify('success', 'Report completed successfully.');
        navigate(`/legacy/effortssummary/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error completing report:', error);
      notify('error', 'Failed to complete report. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MainLayout title="Preview">
      <div className="preview-cr-container">
        <div id="pageContent">
          {pages}
        </div>
        <div className="preview-cr-buttons">
          <button className="preview-cr-button preview-cr-back-button" onClick={handleBack}>Back</button>
          <button className="preview-cr-button preview-cr-save-button" onClick={handleOpenModal}>Sign</button>
        </div>
      </div>
      <Modal
        isOpen={missingFieldsModalIsOpen}
        onRequestClose={closeMissingFieldsModal}
        contentLabel={translations.missingFieldsTitle || 'Missing Fields'}
        className="missing-fields-modal"
        overlayClassName="overlay"
      >
        <div className="missing-fields-modal-content">
          <h2 className="modal-title">
            {translations.missingFieldsTitle || 'Missing Fields'}
          </h2>
          <p className="modal-message">
            {translations.missingFieldsMessage ||
              'The report cannot be signed because the following mandatory fields are missing:'}
          </p>
          <ul className="modal-list">
            {missingFields
              .split(',')
              .filter(Boolean)
              .map((field, index) => (
                <li key={index} className="modal-list-item">
                  {translations[field.trim()] || field.trim()}
                </li>
              ))}
          </ul>
          <p className="modal-message">
            {translations.missingFieldsInstruction ||
              'Please use the back button to complete the missing fields. You can sign and save the report as complete after all required fields are filled out.'}
          </p>
          <div className="modal-buttons">
            <button className="modal-close-button" onClick={closeMissingFieldsModal}>
              {translations.closeButton || 'Close'}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Signature Modal"
        className="preview-cr-modal"
        overlayClassName="preview-cr-overlay"
      >
        <div className="preview-cr-modal-content">
          <h2 className="preview-cr-modal-title">Sign Report</h2>
          <div className="preview-cr-signature-group">
            <h3>Customer Signature</h3>
            <SignatureCanvas
              ref={customerSignatureRef}
              penColor="black"
              canvasProps={{ className: 'preview-cr-signature-canvas' }}
            />
            <button className="preview-cr-reset-button" onClick={() => handleResetSignature(customerSignatureRef)}>
              Reset
            </button>
          </div>
          <div className="preview-cr-signature-group">
            <h3>Technician Signature</h3>
            <SignatureCanvas
              ref={technicianSignatureRef}
              penColor="black"
              canvasProps={{ className: 'preview-cr-signature-canvas' }}
            />
            <button className="preview-cr-reset-button" onClick={() => handleResetSignature(technicianSignatureRef)}>
              Reset
            </button>
          </div>
          <div className="preview-cr-modal-buttons">
            <button className="preview-cr-button" onClick={handleCloseModal}>Cancel</button>
            <button className="preview-cr-button" onClick={handleSaveAndSubmit} disabled={isSaving}>
              Save and Submit
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default Preview;
