import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaListAlt, FaHistory } from 'react-icons/fa'; // Henter "history"-ikonet fra samme pakke
import Modal from 'react-modal';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const WhatWeDone = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [utfort, setUtfort] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialUtfort, setInitialUtfort] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyTexts, setHistoryTexts] = useState([]);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getDoneOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.length > 0) {
          setUtfort(response.data.data[0].utfort || '');
          setInitialUtfort(response.data.data[0].utfort || '');
        }
      } catch (error) {
        console.error('Error fetching done oppdrag:', error);
        notify('error', translations.fetchDoneOppdragError || 'Failed to fetch done oppdrag. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomTexts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomtext/1/?token=${token}`);
        if (response.data && response.data.data) {
          setCustomTexts(response.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching custom texts:', error);
        notify('error', translations.fetchCustomTextsError || 'Failed to fetch custom texts. Please try again.');
      }
    };

    fetchData();
    fetchCustomTexts();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (utfort.trim() !== initialUtfort.trim()) {
      const confirmSave = window.confirm(translations.unsavedChangesMessage || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving information:', error);
          notify('error', translations.saveInformationError || 'Failed to save information. Please try again.');
        }
      } else {
        navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    console.log("handleSave called");
  
    if (utfort.trim() === '') {
      notify('error', translations.describeWhatDoneError || 'Please describe what has been done.');
      return;
    }
  
    try {
      console.log("Sending API request...");
      await apiClient.post(`${config.apiUrl}/updWhatWeDone/?token=${token}`, {
        oppdrag_id,
        utfort: utfort.trim(),
      });
  
      console.log("API request successful");
  
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/method/${behandlingsadrId}/${oppdrag_id}`, navigate);
  
      if (success) {
        notify('success', translations.informationSaved || 'Information saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/method/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error in handleSave:', error);
      notify('error', translations.saveInformationError || 'Failed to save information. Please try again.');
    }
  };
  

  const handleCustomTextClick = () => {
    setIsModalOpen(true);
  };

  const handleHistoryClick = async () => {
    try {
        const response = await apiClient.post(
            `${config.apiUrl}/getReportHistory/?token=${token}`, 
            {
                behandlingsadr_id: behandlingsadrId,
                question: "utfort"
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.data && response.data.data && response.data.data.length > 0) {
            const sortedData = response.data.data
                .filter(item => item.utfort && item.utfort.trim() !== "") 
                .sort((a, b) => new Date(b.dato_ferdig) - new Date(a.dato_ferdig))
                .map(item => ({
                    text: item.utfort.trim(),
                    date: item.dato_ferdig
                }));

            setHistoryTexts(sortedData);
        } else {
            setHistoryTexts([]);
        }
    } catch (error) {
        console.error('Error fetching report history:', error);
        notify('error', translations.fetchHistoryError || 'Failed to fetch report history.');
    }

    setIsHistoryModalOpen(true);
  };

  
  const handleTextSelect = (text) => {
    setUtfort((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
  };

  return (
    <MainLayout title={translations.whatWeDoneTitle || 'What We Done'}>
      <div className="cr-container">
        <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <div className="cr-box">
            <h2 className="cr-heading">{translations.whatHasBeenDoneHeading || 'What has been done:'}</h2>
            <div className="cr-textarea-container">
            <textarea
              className={`cr-textarea ${getTextAreaClass(utfort)}`}
              placeholder={translations.describeWhatHasBeenDonePlaceholder || 'Describe what has been done'}
              value={utfort}
              onChange={(e) => setUtfort(e.target.value)}
            />
              <div className="cr-icon-container">
                <FaListAlt className="cr-icon" onClick={handleCustomTextClick} />
                <FaHistory className="cr-icon" onClick={handleHistoryClick} />
              </div>
            </div>

          </div>
        )}
        <div className="cr-buttons">
          <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || 'Back'}</button>
          <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.saveButton || 'Save'}</button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectCustomTextLabel || 'Select Custom Text'}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectCustomTextTitle || 'Select a text'}</h2>
        <ul className="cr-modal-list">
          {customTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">{translations.closeButton || 'Close'}</button>
      </Modal>
      {/* Report History Modal */}
      <Modal
          isOpen={isHistoryModalOpen}
          onRequestClose={() => setIsHistoryModalOpen(false)}
          contentLabel={translations.reportHistory || 'Report History'}
          className="cr-modal"
          overlayClassName="cr-overlay"
      >
          <h2>{translations.selectHistory || 'Select a previous comment'}</h2>
          <ul className="cr-modal-list">
              {historyTexts.length > 0 ? historyTexts.map((item, index) => (
                  <li key={index} onClick={() => {
                    setUtfort(prev => `${prev}\n${item.text}`);  // Først oppdaterer vi teksten
                    setIsHistoryModalOpen(false);  // Deretter lukker vi modalen
                }} className="cr-modal-item">
                    {item.text} <br /><small>{new Date(item.date).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" })}</small>
                </li>
              )) : <p>{translations.noHistory || 'No previous comment found'}</p>}
          </ul>
          <button onClick={() => setIsHistoryModalOpen(false)} className="cr-button cr-cancel-button">{translations.closeButton || 'Close'}</button>
      </Modal>

    </MainLayout>
  );
};

export default WhatWeDone;
